import { Col, Row } from 'antd';
import SectionPage from '../../widgets/section/SectionPage';
import { useEffect, useState } from 'react';
import { ModelType } from '../../byteio/model';
import { QuestionningResult } from '../../types';
import { loadModel } from '../../localStorage';
import { classifySignature } from '../../analysis/classifier';

type OdorDisplayProps = {
  result: QuestionningResult | null;
};

const OdorDisplay: React.FC<OdorDisplayProps> = ({ result }) => {
  return (
    <SectionPage>
      <Col span={24}>
        <Row justify="center" align="middle" style={{ height: '50vh' }}>
          <img src={`/${result?.label.toUpperCase()}.svg`} alt={result?.label} style={{ width: 'auto', height: '100%', maxHeight: '100%', maxWidth: '80%' }} />
        </Row>
        <Row justify="center">
          <Col span={20}>
            <h1 style={{ textAlign: 'center', fontSize: 30, lineHeight: '30px', marginTop: 20 }}>{result?.label}</h1>
          </Col>
        </Row>
      </Col>
      ;
    </SectionPage>
  );
};

export default OdorDisplay;
