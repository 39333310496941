import { CSSProperties, FC, ReactNode } from 'react';

export const Paper: FC<{ children?: ReactNode; style?: CSSProperties }> = ({ children, style }) => {
  return (
    <div className="paper" style={style}>
      {children}
    </div>
  );
};

export const FlexRow: FC<{ children?: ReactNode; style?: CSSProperties }> = ({ children, style }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export const FlexCol: FC<{ children?: ReactNode; style?: CSSProperties }> = ({ children, style }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        ...style,
      }}
    >
      {children}
    </div>
  );
};
