import { FC, useState } from 'react';
import { useMessageContext } from '../reducers/messageContext';
import { getBleService, navigatorSupportsWebBle, requestBleDevice } from '../ble/ble';
import { Button, Typography } from 'antd';
import { FlexCol, FlexRow } from '../common';
import { PairAndConnectivityWidget } from '../widgets/pairAndConnectivityWidget';
import { SwapOutlined } from '@ant-design/icons';
import { WebBleNotSupportedWidget } from '../widgets/webBleNotSupportedWidget';

export const BleConnectPage: FC = () => {
  const [isPaired, setIsPaired] = useState(false);

  const { csmIsConnected, csmBleDevice, setCSMBleDevice, setCSMIsConnected } = useMessageContext();

  if (!navigatorSupportsWebBle()) {
    return <WebBleNotSupportedWidget />;
  }

  return (
    <FlexCol
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        gap: 0,
      }}
    >
      <Typography.Title
        level={3}
        style={{
          marginBottom: 0,
        }}
      >
        {csmIsConnected ? 'Device connected' : 'Device disconnected'}
      </Typography.Title>
      <Typography.Title
        level={5}
        style={{
          marginTop: 0,
          marginBottom: 30,
        }}
      >
        {csmIsConnected ? 'You can use the app' : 'Connect NeOse Advance to start'}
      </Typography.Title>
      <FlexRow
        style={{
          justifyContent: 'center',
        }}
      >
        <PairAndConnectivityWidget
          name="CoreSensor Module over BLE"
          isConnected={csmIsConnected}
          isPaired={isPaired}
          onPair={async () => {
            try {
              let device = await requestBleDevice();
              try {
                let service = await getBleService(device);
                if (!service) {
                  return;
                }
                setIsPaired(true);
                setCSMBleDevice(device);
                setCSMIsConnected(true);
              } catch (e: any) {
                console.log('error getting service', e.message);
              }
            } catch (e) {}
          }}
        />
      </FlexRow>
      <FlexCol
        style={{
          marginTop: 50,
          textAlign: 'center',
          maxWidth: '80%',
        }}
      >
        <i>
          <span>
            Hint: If you are using the app for the <b>first time</b>, click on <SwapOutlined /> button to your PC to the device
          </span>
          <br />
          <span>
            You should pair <b>Aryballe Neose BLE</b> device
          </span>
        </i>
      </FlexCol>
      <FlexCol
        style={{
          marginTop: 20,
        }}
      >
        <Button
          onClick={async () => {
            if (csmBleDevice && csmBleDevice.gatt && csmBleDevice.gatt.connected) {
              void csmBleDevice.gatt.disconnect();
            }

            if (csmBleDevice && csmBleDevice.forget) {
              void (await csmBleDevice.forget());
            }
            setIsPaired(false);
            setCSMIsConnected(false);
            setCSMBleDevice(undefined);
          }}
        >
          Disconnect
        </Button>
      </FlexCol>
    </FlexCol>
  );
};
