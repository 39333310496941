import { Col, Row } from 'antd';
import IntensityProgress from '../../widgets/IntensityProgress';
import SectionPage from '../../widgets/section/SectionPage';

type AnalyteRecordingProps = {
  signalEnvelopeAvgRef: React.MutableRefObject<number>;
};

const AnalyteRecording: React.FC<AnalyteRecordingProps> = ({ signalEnvelopeAvgRef }) => {
  return (
    <SectionPage height="80vh">
      <Col span={24}>
        <IntensityProgress signalEnvelopeAvgRef={signalEnvelopeAvgRef} />
        <Row justify="center">
          <Col span={20}>
            <h1 style={{ textAlign: 'center', fontSize: 20, lineHeight: '30px', marginTop: 20, marginBottom: 20, fontWeight: 'bold' }}>Smelling odor</h1>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={20} style={{ borderRadius: 20, textAlign: 'center' }}>
            <p style={{ fontSize: 16, lineHeight: '22px' }}>Do not move the sample for at least 3 seconds</p>
          </Col>
        </Row>
      </Col>
    </SectionPage>
  );
};

export default AnalyteRecording;
