import { FC, useEffect, useState } from 'react';
import { Button, Typography } from 'antd';
import { FlexCol, FlexRow } from '../common';
import { JsonView, defaultStyles } from 'react-json-view-lite';
import { CSMMisoFrame, CSM_PROTOCOL_COMMAND_TYPE, CSM_PROTOCOL_EVENT_TYPE, parseBiosensorsSignalEvent, parseEventPayload } from '../serial/csm';
import { useMessageContext } from '../reducers/messageContext';
import { mean } from '../analysis/utils';
import { v4 as uuidv4 } from 'uuid';

export const BleDebugPage: FC = () => {
  const { csmIsConnected, csmMessages, consumeCSMMessage, addCSMCommand } = useMessageContext();

  const [misoResponse, setMisoResponse] = useState<CSMMisoFrame | null>(null);
  const [biosensorsAverageSignal, setBiosensorsAverageSignal] = useState<number | null>(null);

  useEffect(() => {
    for (let message of csmMessages) {
      let frame = message.message;
      if (frame.Type < 0x80) {
        consumeCSMMessage(message.id);
        setMisoResponse(frame);
      } else {
        if (frame.Type === CSM_PROTOCOL_EVENT_TYPE.BiosensorsSignalEvent) {
          consumeCSMMessage(message.id);
          try {
            let eventPayload = parseEventPayload(frame.Payload);
            let sensogram = parseBiosensorsSignalEvent(eventPayload.Data);
            let avg = mean(sensogram);
            setBiosensorsAverageSignal(avg);
          } catch (e: any) {
            console.log('error parsing biosensors signal event', e.message);
          }
        }
      }
    }
  }, [csmMessages]);

  if (!csmIsConnected) {
    return (
      <FlexCol>
        <h1>BLE Page</h1>
        <p>Device not connected</p>
      </FlexCol>
    );
  }

  return (
    <FlexCol>
      <Typography.Title level={2}>BLE Debug Page</Typography.Title>
      <FlexRow
        style={{
          alignItems: 'flex-start',
        }}
      >
        <FlexCol>
          <Typography.Title level={5}>MISO Response:</Typography.Title>
          {misoResponse && <JsonView data={misoResponse} style={defaultStyles} />}
        </FlexCol>
        <FlexCol>
          <Typography.Title level={5}>AVG:</Typography.Title>
          {biosensorsAverageSignal?.toFixed(10)}
        </FlexCol>
      </FlexRow>
      <FlexRow>
        <Button
          type="primary"
          onClick={async () => {
            try {
              addCSMCommand({
                id: uuidv4().toString(),
                message: {
                  CmdType: CSM_PROTOCOL_COMMAND_TYPE.StartSampling,
                },
              });
            } catch (e: any) {
              console.log('error writing mosi characteristic', e.message);
            }
          }}
        >
          Start
        </Button>
        <Button
          type="primary"
          onClick={async () => {
            try {
              addCSMCommand({
                id: uuidv4().toString(),
                message: {
                  CmdType: CSM_PROTOCOL_COMMAND_TYPE.StopSampling,
                },
              });
            } catch (e: any) {
              console.log('error writing mosi characteristic', e.message);
            }
          }}
        >
          Stop
        </Button>
        <Button
          type="primary"
          onClick={async () => {
            try {
              addCSMCommand({
                id: uuidv4().toString(),
                message: {
                  CmdType: CSM_PROTOCOL_COMMAND_TYPE.GetVersions,
                },
              });
            } catch (e: any) {
              console.log('error writing mosi characteristic', e.message);
            }
          }}
        >
          GetVersions
        </Button>
        <Button
          type="primary"
          onClick={async () => {
            try {
              addCSMCommand({
                id: uuidv4().toString(),
                message: {
                  CmdType: CSM_PROTOCOL_COMMAND_TYPE.SetReference,
                },
              });
            } catch (e: any) {
              console.log('error writing mosi characteristic', e.message);
            }
          }}
        >
          SetReference
        </Button>
      </FlexRow>
    </FlexCol>
  );
};
