import { Col, Row, Spin, Typography } from 'antd';
import { FC, useEffect, useState } from 'react';
import { FlexCol, Paper } from '../common';
import { useMessageContext } from '../reducers/messageContext';
import { BleConnectPage } from './bleConnectPage';
import { CSM_PROTOCOL_COMMAND_TYPE, VersionsInfoMap, parseVersionsResponse } from '../serial/csm';
import { v4 as uuidv4 } from 'uuid';
import { saveSpotsGrid1D } from '../localStorage';

export const BleDevicePage: FC = () => {
  const { csmBleDevice, csmIsConnected, csmMessages, consumeCSMMessage, addCSMCommand } = useMessageContext();

  const [versionsInfo, setVersionsInfo] = useState<VersionsInfoMap | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    addCSMCommand({
      id: uuidv4().toString(),
      message: {
        CmdType: CSM_PROTOCOL_COMMAND_TYPE.GetVersions,
      },
    });
  }, []);

  useEffect(() => {
    for (let message of csmMessages) {
      let frame = message.message;
      if (frame.Type === CSM_PROTOCOL_COMMAND_TYPE.GetVersions) {
        consumeCSMMessage(message.id);
        try {
          // TODO: response status code is encoded as ASCII
          // "1" == 0x31 (49)
          let versioninfo = parseVersionsResponse(frame.Payload.slice(1));
          setVersionsInfo(versioninfo);
          setIsLoading(false);
        } catch (e: any) {
          console.log('error parsing versions response', e.message);
        }
      }
    }
  }, [csmMessages]);

  if (!csmIsConnected) {
    return <BleConnectPage />;
  }

  return (
    <Row>
      <Col xs={24}>
        <Paper>
          <FlexCol
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              gap: 3,
            }}
          >
            <Typography.Title
              level={3}
              style={{
                textAlign: 'center',
              }}
            >
              Aryballe CoreSensorModule over <span style={{ color: '#0082FC' }}>BLE</span>
            </Typography.Title>
            {isLoading ? (
              <Spin />
            ) : (
              versionsInfo !== null && (
                <FlexCol
                  style={{
                    gap: 1,
                  }}
                >
                  {Object.entries(versionsInfo).map(([key, value]) => {
                    return (
                      <span key={key}>
                        <b>{key.toUpperCase()}:</b> {value}
                      </span>
                    );
                  })}
                </FlexCol>
              )
            )}
          </FlexCol>
        </Paper>
      </Col>
      <Col xs={24}>{/* <SpotfileManagementWidget /> */}</Col>
    </Row>
  );
};
