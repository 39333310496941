import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { MessageProvider } from './components/reducers/messageContext';
import { MetadataProvider } from './components/reducers/metadataContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { toast } from 'react-toastify';
import { AppstoreAddOutlined, ArrowUpOutlined, HistoryOutlined } from '@ant-design/icons';
import { loadLastStandaloneAppNotificationShownTimestamp, saveLastStandaloneAppNotificationShownTimestamp } from './components/localStorage';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

window.addEventListener('DOMContentLoaded', () => {
  let displayMode = 'browser-tab';
  if (window.matchMedia('(display-mode: standalone)').matches) {
    displayMode = 'standalone';
  }
  let lastStandaloneAppNotificationShownTimestamp = loadLastStandaloneAppNotificationShownTimestamp();
  console.log('app root: display mode is', displayMode, lastStandaloneAppNotificationShownTimestamp);
  if (displayMode === 'browser-tab') {
    if (lastStandaloneAppNotificationShownTimestamp !== null && Date.now() - lastStandaloneAppNotificationShownTimestamp < 1000 * 60 * 60 * 3) {
      return;
    }
    toast(
      <>
        Did you know you can install the <b>NeOse Webapp</b> on your device by clicking on the <b>'Install'</b> button above <ArrowUpOutlined />?
      </>,
      {
        icon: <AppstoreAddOutlined />,
        delay: 1000,
        closeOnClick: true,
      }
    );
    saveLastStandaloneAppNotificationShownTimestamp(Date.now());
  }
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* MetadataProvider provides state for devices metadata (memInfos, versions etc) storage*/}
      <MetadataProvider>
        {/* MessageProvider provides with an async message queue*/}
        <MessageProvider>
          <App />
        </MessageProvider>
      </MetadataProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register({
//   onUpdate: (registration) => {
//     toast(
//       <>
//         A <b>new version</b> of the NeOse Webapp has been installed for you! <b>Just reopen</b> the app and you're good to go!
//       </>,
//       {
//         icon: <HistoryOutlined />,
//       }
//     )
//   }
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
