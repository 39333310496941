import { Progress, Row } from 'antd';
import { useEffect, useState } from 'react';
import { ARYBALLE_COLOR_GRAY, colorPalette } from '../utils';

const DEFAULT_INTENSITY = 0;
const MAX_INTENSITY = 10;

const INTENSITY_DISPLAY_DELAY = 300; // ms

type IntensityProgressProps = {
  signalEnvelopeAvgRef: React.MutableRefObject<number>;
};

const IntensityProgress: React.FC<IntensityProgressProps> = ({ signalEnvelopeAvgRef }) => {
  const [intensity, setIntensity] = useState<number>(DEFAULT_INTENSITY);
  const [intensityProgress, setIntensityProgress] = useState<number>(0); // intensity tranformation in percent for progress bar

  useEffect(() => {
    setIntensity(Math.round(100 * Number(signalEnvelopeAvgRef.current)) / 100);
  }, [signalEnvelopeAvgRef.current]);

  useEffect(() => {
    setIntensityProgress((100 * intensity) / MAX_INTENSITY);
  }, [intensity]);

  return (
    <Row justify="center" align="middle" style={{ minHeight: '40vh' }}>
      <Progress
        type="dashboard"
        percent={intensityProgress}
        strokeLinecap="round"
        strokeColor={colorPalette.cyan}
        trailColor={ARYBALLE_COLOR_GRAY}
        gapDegree={30}
        gapPosition="left"
        strokeWidth={5}
        size={250}
        format={() => (
          <>
            <p>{intensity.toFixed(2)}</p>
            <p style={{ fontSize: 14 }}>Intensity (rad)</p>
          </>
        )}
      />
    </Row>
  );
};

export default IntensityProgress;
