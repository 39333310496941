import { FC, useEffect, useState } from 'react';
import { readPayloadBlocking } from '../serial/serial';
import { useMessageContext } from '../reducers/messageContext';
import { v4 as uuidv4 } from 'uuid';
import { RefDesignProtocol } from '../../ref_design_protocol';
import { detectRefkitPort, initializeRefkit } from '../serial/refkit';
import { DEFAULT_BLOCKING_LOOP_UNEXPECTED_ERROR_WAIT, DEFAULT_DEVICE_DETECTION_INTERVAL, REFKIT_LENGTH_N_BYTES } from '../serial/constants';
import { useMetadataContext } from '../reducers/metadataContext';
import { ConnectionIndicator } from '../widgets/connectionIndicator';
import { navigatorSupportsSerial } from '../serial/utils';

export const RefkitMessageDispatcher: FC<{
  minimized: boolean;
}> = ({ minimized }) => {
  const { refkitMessages, addRefkitMessage, refkitPort, setRefkitPort, refkitIsConnected, setRefkitIsConnected } = useMessageContext();
  const { setRefkitVersion, setRefkitCapabilities, setRefkitConfig, setRefkitMemInfosImage, setRefkitMemInfosCoreSensor } = useMetadataContext();

  useEffect(() => {
    if (!navigatorSupportsSerial()) {
      return;
    }
    if (refkitPort === undefined || refkitPort.readable === null || refkitPort.writable === null) {
      setRefkitIsConnected(false);
      return;
    }
    console.log('refkit infinite loop: start');
    setRefkitIsConnected(true);

    var reader: ReadableStreamBYOBReader | null = null;

    const loop = async () => {
      if (refkitPort === undefined || refkitPort.readable === null || refkitPort.writable === null) {
        setRefkitIsConnected(false);
        return;
      }
      reader = refkitPort.readable.getReader({ mode: 'byob' });
      // let t0 = Date.now()
      while (true) {
        try {
          let payloadBytes = await readPayloadBlocking(reader, REFKIT_LENGTH_N_BYTES);
          if (payloadBytes.length === 0) {
            console.log('continuing..');
            continue;
          }
          // let t1 = Date.now()
          // console.log("refkit message received in", t1 - t0, "ms")
          // t0 = t1
          let slaveMsg = RefDesignProtocol.SlaveRoot.decode(payloadBytes);
          addRefkitMessage({
            id: uuidv4().toString(),
            ts: Date.now(),
            message: slaveMsg,
          });
        } catch (e: any) {
          if (e && e.message) {
            if (e.message.includes('has been released') || e.message.includes('Releasing')) {
              console.log('refkit port reader has been released; breaking..');
              break;
            }
            if (e.message.includes('device has been lost')) {
              console.log(e.message);
              break;
            }
            if (e.message.includes('Invalid CRC')) {
              // console.debug(e.message)
              continue;
            }
          }
          console.log('refkit infinite loop unexpected error:', e);
          await new Promise((resolve) => setTimeout(resolve, DEFAULT_BLOCKING_LOOP_UNEXPECTED_ERROR_WAIT));
        }
      }
    };

    loop().then(() => {
      console.log('refkit infinite loop: end');
    });

    return () => {
      console.log('refkit infinite loop: releasing the reader');
      if (reader !== null) {
        reader.releaseLock();
      }
    };
  }, [refkitPort]);

  useEffect(() => {
    if (!navigatorSupportsSerial()) {
      return;
    }
    var mutex = true;
    let i = setInterval(async () => {
      if (!mutex) {
        console.debug('refkit port detection: port detection mutex is false - skipping');
        return;
      }
      if (refkitPort !== undefined && refkitPort.readable !== null && refkitPort.writable !== null) {
        return;
      }
      console.debug('setting refkit port detection mutex to false');
      mutex = false;
      // setRefkitPort(undefined)
      setRefkitIsConnected(false);
      try {
        let port = await detectRefkitPort();
        if (port === undefined) {
          console.debug('no refkit port found');
          mutex = true;
          return;
        }
        try {
          let { version, capabilities, config, memoryInfoImage, memoryInfoCoreSensor } = await initializeRefkit(port);
          setRefkitVersion(version);
          setRefkitCapabilities(capabilities);
          setRefkitConfig(config);
          setRefkitMemInfosImage(memoryInfoImage);
          setRefkitMemInfosCoreSensor(memoryInfoCoreSensor);
        } catch (e) {
          console.log('error initializing refkit:', e);
          void (await port.close());
          mutex = true;
          return;
        }
        setRefkitPort(port);
        console.debug('refkit port found', port);
      } catch (e) {
        console.debug('no refkit port found');
      }
      console.debug('setting refkit port detection mutex to true');
      mutex = true;
      return;
    }, DEFAULT_DEVICE_DETECTION_INTERVAL);
    return () => {
      console.debug('clearing interval for refkit port detection');
      clearInterval(i);
      console.debug('setting refkit port detection mutex to true');
      mutex = true;
      return;
    };
  }, [refkitPort]);

  if (!navigatorSupportsSerial()) {
    return null;
  }

  return <ConnectionIndicator name="CoreSensor Module" connectPath="/connect/serial" isConnected={refkitIsConnected} minimized={minimized} queueLength={refkitMessages.length} />;
};
