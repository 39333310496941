import { useEffect, useRef, useState } from 'react';
import BaselineRecording from './BaselineRecording';
import AnalyteRecording from './AnalyteRecording';
import OdorAnalysis from './OdorAnalysis';
import OdorDisplay from './OdorDisplay';
import SensorCleaning from './SensorCleaning';
import { useMetadataContext } from '../../reducers/metadataContext';
import { useMessageContext } from '../../reducers/messageContext';
import { DeviceValue, RecordKey, commitSensogramPartition } from '../../idb/idb';
import { refkitProtocolSetMMI } from '../../serial/refkit';
import { loadModel, loadSpotsGrid1D } from '../../localStorage';
import { Mutex, MutexInterface, withTimeout } from 'async-mutex';
import { mmi2mzi } from '../../analysis/mzi';
import { DEFAULT_IMMEDIATE_RECOGNITION_BACKWARD_WINDOW_SEC, DEFAULT_PLOT_DECIMATED_FPS, DEFAULT_RAW_FPS, DEFAULT_STORAGE_DECIMATED_FPS, IDB_PARTITION_WINDOW_SIZE, PLOT_WINDOW_SIZE } from '../../constants';
import { mean, standardDeviation, transpose } from '../../analysis/utils';
import { DEFAULT_ODOR_PRESENCE_DEACTIVATION_PERCENT_OF_MAX_VALUE } from '../../serial/constants';
import { aggregateSignature, normalizeL2, sortSignature } from '../../analysis/compute';
import { ARYBALLE_COLOR_CYAN, ARYBALLE_COLOR_GRAY, ARYBALLE_COLOR_GRAY_DARK, DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE, PEPTIDE_COLOR_MAP_VDW, colorHexToRGBA, rowIdxToLetter, spotsgrid1dIndexTo2dCoordinates } from '../../utils';
import uPlot from 'uplot';
import { classifySignature } from '../../analysis/classifier';
import { ModelType } from '../../byteio/model';
import { QuestionningResult } from '../../types';

type SerialOdorIdentificationProps = {};
export enum QuestioningState {
  SensorCleaning = 'SensorCleaning',
  BaselineRecording = 'BaselineRecording',
  AnalyteRecording = 'AnalyteRecording',
  OdorAnalysis = 'OdorAnalysis',
  OdorDisplay = 'OdorDisplay',
}
const SerialOdorIdentification: React.FC<SerialOdorIdentificationProps> = () => {
  const [questioningState, setQuestioningState] = useState<QuestioningState>(QuestioningState.BaselineRecording);

  const [questionningSignature, setQuestionningSignature] = useState<number[] | null>(null);
  const [questionningSpotsgrid1d, setQuestionningSpotsgrid1d] = useState<number[] | null>(null);

  const { refkitMessages, refkitPort, refkitIsConnected, consumeRefkitMessage, clearRefkitMessages } = useMessageContext();

  const { thimphuFspMetadata, refkitVersion, refkitConfig } = useMetadataContext();

  const [mziUplotOptions, setMziUplotOptions] = useState<uPlot.Options | null>(null);

  const [mziUplotData, setMziUplotData] = useState<uPlot.AlignedData>([]);
  const [fpsUplotData, setFpsUplotData] = useState<uPlot.AlignedData>([]);

  const mziTargetRef = useRef<HTMLDivElement>(null);
  const mziUplotRef = useRef<uPlot | null>(null);
  const mziTooltipRef = useRef<HTMLDivElement>(null);

  const fpsTargetRef = useRef<HTMLDivElement>(null);
  const fpsUplotRef = useRef<uPlot | null>(null);

  const firstMZIsRef = useRef<number[] | null>(null);
  const previousMZIsRef = useRef<number[] | null>(null);
  const KsRef = useRef<number[] | null>(null);

  const noizeLevelRef = useRef<number>(0);
  const isOdorPresentRef = useRef<boolean>(false);
  const odorPresenceThresholdLevelRef = useRef<number>(0);
  const maxOdorPresentValue = useRef<number>(0);
  const odorPresentStartTimestampRef = useRef<number>(0);
  const odorPresentStopTimestampRef = useRef<number>(0);
  const odorPresentLastRecognitionTimestampRef = useRef<number>(0);
  const signalEnvelopeMinRef = useRef<number>(0);
  const signalEnvelopeMaxRef = useRef<number>(0);
  const signalEnvelopeAvgRef = useRef<number>(0);

  const decimatedMZISeriesRef = useRef<number[][]>([]);
  const rawMZISeriesRef = useRef<number[][]>([]);

  const decimatedTimestampSeriesRef = useRef<number[]>([]);
  const rawTimestampSeriesRef = useRef<number[]>([]);

  const decimatedMZIPartitionSeriesRef = useRef<number[][]>([]);
  const decimatedTimestampPartitionSeriesRef = useRef<number[]>([]);

  const decimatedFpsTimeseriesRef = useRef<number[]>([]);
  const rawFpsTimeseriesRef = useRef<number[]>([]);

  const [rawFps, setRawFps] = useState<number>(0);
  const [decimatedFps, setDecimatedFps] = useState<number>(0);

  const lastDecimationTickRef = useRef<number>(0);

  const [currentSpotsgrid1d, setCurrentSpotsgrid1d] = useState<number[] | null>(null);
  const [aggregatedIndicesMap, setAggregatedIndicesMap] = useState<Record<number, number[]>>({});

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const recordKeyRef = useRef<RecordKey | null>(null);

  const [isSensing, setIsSensing] = useState<boolean>(true);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [recordStartTimestamp, setRecordStartTimestamp] = useState<number>(0);

  const [deviceValue, setDeviceValue] = useState<DeviceValue | null>(null);
  const [isRecordModalOpen, setIsRecordModalOpen] = useState<boolean>(false);

  const [shouldAggregate, setShouldAggregate] = useState<boolean>(true);
  const [showDebugInfo, setShowDebugInfo] = useState<boolean>(false);
  const [shouldRedraw, setShouldRedraw] = useState<boolean>(false);
  const [pinLastQuestionningResult, setPinLastQuestionningResult] = useState<boolean>(true);
  const messageQueueMutexRef = useRef<MutexInterface>(withTimeout(new Mutex(), 300));

  const [currentModel, setCurrentModel] = useState<ModelType | null>(null);
  const [questionningResult, setQuestionningResult] = useState<QuestionningResult | null>(null);

  useEffect(() => {
    let t = setTimeout(() => {
      if (!refkitPort || !refkitIsConnected) {
        return;
      }
      clearRefkitMessages();
      refkitProtocolSetMMI(refkitPort, true).then(() => {
        console.log('sense page: set refkit mmi to true on mount');
        setIsLoading(false);
      });
    }, 500);
    return () => {
      clearTimeout(t);
      if (!refkitPort || !refkitIsConnected) {
        return;
      }
      clearRefkitMessages();
      refkitProtocolSetMMI(refkitPort, false).then(() => {
        setIsLoading(true);
        console.log('sense page: set refkit mmi to false on unmount');
      });
    };
  }, [refkitPort, refkitIsConnected, currentSpotsgrid1d]);

  useEffect(() => {
    let _spotsgrid1d = loadSpotsGrid1D();
    if (!_spotsgrid1d) {
      console.log('sense page: spotsgrid1d is empty');
      return;
    }
    setCurrentSpotsgrid1d(_spotsgrid1d);
    // Aggregate MZIs by peptide
    let _aggregationIndicesMap: Record<number, number[]> = {};
    for (let i = 0; i < _spotsgrid1d.length; i++) {
      let aggKey = _spotsgrid1d[i];
      if (aggKey < 0) {
        continue;
      }
      if (_aggregationIndicesMap[aggKey] === undefined) {
        _aggregationIndicesMap[aggKey] = [];
      }
      _aggregationIndicesMap[aggKey].push(i);
    }
    setAggregatedIndicesMap(_aggregationIndicesMap);
    // console.log("sense page: _aggregationIndicesMap", _aggregationIndicesMap)
  }, [thimphuFspMetadata]);

  useEffect(() => {
    if (refkitMessages.length === 0) {
      return;
    }
    if (messageQueueMutexRef.current.isLocked()) {
      return;
    }
    let t = Date.now();
    // console.log("sense page: acquiring mutex..")
    messageQueueMutexRef.current
      .acquire()
      .then((release) => {
        // console.log("sense page: acquired mutex in ", Date.now() - t, "ms")
        let nFramesOnOneMutexLock = 0;
        for (let message of refkitMessages) {
          if (message.message.result && message.message.result.header && !message.message.result.header.status) {
            console.log('sense page: refkit message error', message.message.result.header.reason);
            consumeRefkitMessage(message.id);
            continue;
          }
          // console.log("sense page: refkit message", message)
          if (!message.message.event || !message.message.event.algo) {
            console.log('sense page: refkit message is not an event', message.message);
            consumeRefkitMessage(message.id);
          } else {
            nFramesOnOneMutexLock++;
            consumeRefkitMessage(message.id);

            // load the spotsgrid
            if (!currentSpotsgrid1d) {
              console.log('sense page: spotsgrid is empty');
              return;
            }

            let tick = message.ts;
            // console.log("sense page: ts", ts)
            let mmis = message.message.event.algo.peaks;
            if (!mmis || mmis.length === 0) {
              console.log('sense page: peaks are empty');
              continue;
            }

            let mzis = mmi2mzi(mmis);

            if (KsRef.current === null) {
              KsRef.current = new Array(mzis.length).fill(0);
            }

            for (let i = 0; i < mzis.length; i++) {
              let k = KsRef.current[i];
              mzis[i] += k * 2 * Math.PI;
            }

            if (previousMZIsRef.current !== null) {
              for (let i = 0; i < mzis.length; i++) {
                let mzi = mzis[i];
                const previousMzi = previousMZIsRef.current[i];
                let k = KsRef.current[i];
                let diff = mzi - previousMzi;
                if (diff > Math.PI) {
                  k -= 1;
                  mzi -= 2 * Math.PI;
                } else if (diff < -Math.PI) {
                  k += 1;
                  mzi += 2 * Math.PI;
                }
                mzis[i] = mzi;
                KsRef.current[i] = k;
              }
            }
            previousMZIsRef.current = mzis;

            rawMZISeriesRef.current.push(mzis);
            rawTimestampSeriesRef.current.push(tick);

            if (tick - lastDecimationTickRef.current < 1000 / DEFAULT_PLOT_DECIMATED_FPS) {
              continue;
            }
            lastDecimationTickRef.current = tick;

            decimatedTimestampSeriesRef.current.push(tick);
            // console.log("sense page: decimated timestamp timeseries", decimatedTimestampTimeseriesRef.current)

            if (decimatedTimestampSeriesRef.current.length > PLOT_WINDOW_SIZE) {
              decimatedTimestampSeriesRef.current.shift();
            }

            // calculate raw timestamp intervals
            let rawTimestampIntervals: number[] = [];
            for (let i = 0; i < rawTimestampSeriesRef.current.length - 1; i++) {
              rawTimestampIntervals[i] = rawTimestampSeriesRef.current[i + 1] - rawTimestampSeriesRef.current[i];
            }
            rawTimestampSeriesRef.current = [];
            let rawTimestampInterval = rawTimestampIntervals.reduce((a, b) => a + b, 0) / rawTimestampIntervals.length;
            let rawFps = 1000 / rawTimestampInterval;
            setRawFps(rawFps);

            if (!isNaN(rawFps)) {
              rawFpsTimeseriesRef.current.push(rawFps);
            }
            if (rawFpsTimeseriesRef.current.length > PLOT_WINDOW_SIZE) {
              rawFpsTimeseriesRef.current.shift();
            }

            // calculate decimated timestamp intervals
            let decimatedTimestampIntervals: number[] = [];
            for (let i = 0; i < decimatedTimestampSeriesRef.current.length - 1; i++) {
              decimatedTimestampIntervals[i] = decimatedTimestampSeriesRef.current[i + 1] - decimatedTimestampSeriesRef.current[i];
            }
            let decimatedTimestampInterval = decimatedTimestampIntervals.reduce((a, b) => a + b, 0) / decimatedTimestampIntervals.length;
            // console.log("sense page: decimated timestamp interval", decimatedTimestampInterval, decimatedTimestampIntervals)
            let decimatedFps = 1000 / decimatedTimestampInterval;
            setDecimatedFps(decimatedFps);

            if (!isNaN(decimatedFps)) {
              decimatedFpsTimeseriesRef.current.push(decimatedFps);
            }
            if (decimatedFpsTimeseriesRef.current.length > PLOT_WINDOW_SIZE) {
              decimatedFpsTimeseriesRef.current.shift();
            }

            // decimate MZIs by averaging over DECIMATION_WINDOW_SIZE
            let decimatedMzis: number[] = [];
            for (let i = 0; i < currentSpotsgrid1d.length; i++) {
              let sum = 0;
              for (let j = 0; j < rawMZISeriesRef.current.length; j++) {
                sum += rawMZISeriesRef.current[j][i];
              }
              decimatedMzis[i] = sum / rawMZISeriesRef.current.length;
            }
            rawMZISeriesRef.current = [];

            // subtract first mzis and save them
            if (firstMZIsRef.current === null) {
              firstMZIsRef.current = [...decimatedMzis];
              // console.log("sense page: first mzis is null. setting to", firstMZIsRef.current)
            }
            for (let i = 0; i < currentSpotsgrid1d.length; i++) {
              decimatedMzis[i] -= firstMZIsRef.current[i];
            }

            decimatedMZISeriesRef.current.push(decimatedMzis);
            if (decimatedMZISeriesRef.current.length > PLOT_WINDOW_SIZE) {
              decimatedMZISeriesRef.current.shift();
            }

            decimatedTimestampPartitionSeriesRef.current.push(tick);
            decimatedMZIPartitionSeriesRef.current.push(decimatedMzis); // save non-aggreated mzis regardless of shouldAggregate

            if (decimatedMZIPartitionSeriesRef.current.length >= IDB_PARTITION_WINDOW_SIZE) {
              if (isRecording && recordKeyRef.current !== null) {
                // average decimate decimatedMZIPartitionSeriesRef by 2 for storage
                // (storage decimation is half the plotting one)
                let storageDecimatedMZIPartitionSeries: number[][] = [];
                let storageDecimatedTimestampPartitionSeries: number[] = [];
                let storageDecimationFactor = Math.floor(DEFAULT_PLOT_DECIMATED_FPS / DEFAULT_STORAGE_DECIMATED_FPS);
                for (let i = 0; i < decimatedMZIPartitionSeriesRef.current.length; i += storageDecimationFactor) {
                  let storageDecimatedMzis: number[] = [];
                  for (let j = 0; j < currentSpotsgrid1d.length; j++) {
                    let sum = 0;
                    for (let ii = 0; ii < storageDecimationFactor; ii++) {
                      sum += decimatedMZIPartitionSeriesRef.current[i + ii][j];
                    }
                    storageDecimatedMzis[j] = sum / storageDecimationFactor;
                  }
                  storageDecimatedMZIPartitionSeries.push(storageDecimatedMzis);
                  // timestamps are just subsampled (not averaged)
                  storageDecimatedTimestampPartitionSeries.push(decimatedTimestampPartitionSeriesRef.current[i]);
                }
                commitSensogramPartition(recordKeyRef.current, storageDecimatedMZIPartitionSeries, storageDecimatedTimestampPartitionSeries)
                  .then(() => {
                    console.log('sense page: saved partition');
                  })
                  .catch((e: any) => {
                    console.log('sense page: could not save partition', e);
                  });
              }
              decimatedMZIPartitionSeriesRef.current = [];
              decimatedTimestampPartitionSeriesRef.current = [];
            }

            let seriesLabels: number[] = [];
            if (shouldAggregate) {
              seriesLabels = Object.keys(aggregatedIndicesMap).map((aggKey) => parseInt(aggKey));
            } else {
              seriesLabels = [...currentSpotsgrid1d];
            }
            let nDims = seriesLabels.length;

            // build mzi uplot data
            let X = decimatedTimestampSeriesRef.current.map((ts) => ts / 1000);

            // aggregate mzis timeseries (frame by frame) if needed
            let finalMZIsSeries: number[][] = [];
            if (shouldAggregate) {
              for (let j = 0; j < decimatedMZISeriesRef.current.length; j++) {
                let finalMZIs: number[] = [];
                for (let aggKey in aggregatedIndicesMap) {
                  let aggIndices = aggregatedIndicesMap[aggKey];
                  let sum = 0;
                  for (let i = 0; i < aggIndices.length; i++) {
                    sum += decimatedMZISeriesRef.current[j][aggIndices[i]];
                  }
                  finalMZIs.push(sum / aggIndices.length);
                }
                finalMZIsSeries.push(finalMZIs);
              }
            } else {
              finalMZIsSeries = decimatedMZISeriesRef.current;
            }

            if (finalMZIsSeries.length < 2) {
              continue;
            }

            //
            let lastFrame: number[] = finalMZIsSeries[finalMZIsSeries.length - 1];
            let lastFrameSum: number = 0;
            signalEnvelopeMinRef.current = 1e6;
            signalEnvelopeMaxRef.current = -1e6;
            for (let i = 0; i < lastFrame.length; i++) {
              if (lastFrame[i] < signalEnvelopeMinRef.current) {
                signalEnvelopeMinRef.current = lastFrame[i];
              }
              if (lastFrame[i] > signalEnvelopeMaxRef.current) {
                signalEnvelopeMaxRef.current = lastFrame[i];
              }
              lastFrameSum += lastFrame[i];
            }
            signalEnvelopeAvgRef.current = lastFrameSum / lastFrame.length;
            let previousFrameMean: number = mean(finalMZIsSeries[finalMZIsSeries.length - 2]);

            let avgSeries: number[] = [];
            for (let i = 0; i < finalMZIsSeries.length; i++) {
              avgSeries.push(mean(finalMZIsSeries[i]));
            }

            if (!isOdorPresentRef.current && noizeLevelRef.current > 0 && signalEnvelopeAvgRef.current > noizeLevelRef.current) {
              odorPresenceThresholdLevelRef.current = mean([previousFrameMean, signalEnvelopeAvgRef.current]);
              odorPresentStartTimestampRef.current = decimatedTimestampSeriesRef.current[decimatedTimestampSeriesRef.current.length - 1];
              odorPresentStopTimestampRef.current = 0;
              odorPresentLastRecognitionTimestampRef.current = Date.now();
              isOdorPresentRef.current = true;
              //passage analyte state
              setQuestioningState(QuestioningState.AnalyteRecording);
            }

            if (isOdorPresentRef.current && signalEnvelopeAvgRef.current < Math.max(maxOdorPresentValue.current * DEFAULT_ODOR_PRESENCE_DEACTIVATION_PERCENT_OF_MAX_VALUE, odorPresenceThresholdLevelRef.current)) {
              isOdorPresentRef.current = false;
              maxOdorPresentValue.current = 0;
              odorPresenceThresholdLevelRef.current = 0;
              odorPresentStopTimestampRef.current = decimatedTimestampSeriesRef.current[decimatedTimestampSeriesRef.current.length - 1];
              if (!pinLastQuestionningResult) {
                setQuestionningSignature(null);
              } else {
                constructSignatureAndRecognize();
              }
              setQuestioningState(QuestioningState.OdorAnalysis);
              // fin d'analyte
            }

            if (!isOdorPresentRef.current) {
              let noizeSeries = avgSeries.slice(-4 * DEFAULT_PLOT_DECIMATED_FPS, -1 * DEFAULT_PLOT_DECIMATED_FPS);
              noizeLevelRef.current = mean(noizeSeries) + standardDeviation(noizeSeries) * 12;
            }

            if (isOdorPresentRef.current) {
              maxOdorPresentValue.current = Math.max(maxOdorPresentValue.current, signalEnvelopeAvgRef.current);
            }

            if (isOdorPresentRef.current && Date.now() - odorPresentLastRecognitionTimestampRef.current > 250) {
              constructSignatureAndRecognize();
              odorPresentLastRecognitionTimestampRef.current = Date.now();
            }

            // build uplot options
            // TODO DELETE THIS
            // if (mziUplotOptions === null) {
            //   const opts: uPlot.Options = {
            //     id: `uplot-chart-mmi`,
            //     width: 0,
            //     height: 0,
            //     padding: [0, 50, 0, 0],
            //     legend: {
            //       show: false,
            //     },
            //     scales: {
            //       y: {
            //         range: (u, min, max) => (min < 0 ? [min, max] : [0, max]),
            //       },
            //     },
            //     pxAlign: 0,
            //     series: [
            //       {},
            //       // sensogram series
            //       ...seriesLabels.map((spotInt) => {
            //         let color = DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE;
            //         let peptideInt = spotInt;
            //         if (peptideInt < 0) {
            //           peptideInt *= -1;
            //         }
            //         let peptideStr = spotInt.toString();
            //         if (peptideStr.length === 3 && peptideStr[2] === '4') {
            //           peptideInt = parseInt(peptideStr.slice(0, 2));
            //         }
            //         if (PEPTIDE_COLOR_MAP_VDW[peptideInt]) {
            //           color = PEPTIDE_COLOR_MAP_VDW[peptideInt];
            //         }
            //         let label = peptideInt.toString();
            //         return {
            //           show: spotInt > 1,
            //           spanGaps: false,
            //           label: label,
            //           stroke: color,
            //           width: 2,
            //         } as uPlot.Series;
            //       }),
            //       // Avg series
            //       {
            //         show: true,
            //         spanGaps: false,
            //         label: 'Avg',
            //         stroke: 'rgba(0,0,0,0.7)',
            //         width: 2,
            //       } as uPlot.Series,
            //     ],
            //     hooks: {
            //       setSeries: [
            //         (u, seriesIdx) => {
            //           try {
            //             // console.log("sense page: set series", seriesIdx)
            //             if (mziTooltipRef.current === null) {
            //               return;
            //             }
            //             if (seriesIdx === null) {
            //               return;
            //             }
            //             let seriesLabel = u.series[seriesIdx].label;
            //             if (seriesLabel === undefined) {
            //               return;
            //             }
            //             let peptideInt = parseInt(seriesLabel);
            //             let color = DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE;
            //             if (seriesLabel === 'Avg') {
            //               color = 'rgba(0,0,0,0.7)';
            //             }
            //             if (PEPTIDE_COLOR_MAP_VDW[peptideInt]) {
            //               color = PEPTIDE_COLOR_MAP_VDW[peptideInt];
            //             }
            //             let tooltip = seriesLabel;
            //             if (!shouldAggregate) {
            //               let [row, col] = spotsgrid1dIndexTo2dCoordinates(seriesIdx - 1);
            //               let rowStr = rowIdxToLetter(row);
            //               tooltip += ` [${rowStr}${col}]`;
            //             }
            //             mziTooltipRef.current.innerHTML = `<b>${tooltip}</b>`;
            //             mziTooltipRef.current.style.backgroundColor = color;
            //             mziTooltipRef.current.style.color = 'white';
            //             mziTooltipRef.current.style.border = '1px solid white';
            //             mziTooltipRef.current.style.borderRadius = '5px';
            //             mziTooltipRef.current.style.padding = '5px';
            //           } catch (e) {
            //             console.log('sense page: set series error', e);
            //           }
            //         },
            //       ],
            //       draw: [
            //         // draw horizontal line at noize level
            //         (u) => {
            //           const ctx = u.ctx;
            //           const x0 = u.bbox.left;
            //           const x1 = u.bbox.left + u.bbox.width;
            //           const y = u.valToPos(noizeLevelRef.current, 'y', true);
            //           ctx.strokeStyle = colorHexToRGBA(ARYBALLE_COLOR_GRAY, 1);
            //           ctx.lineWidth = 2;
            //           ctx.setLineDash([20, 5, 5, 5]);
            //           ctx.beginPath();
            //           ctx.moveTo(x0, y);
            //           ctx.lineTo(x1, y);
            //           ctx.stroke();
            //         },
            //         // draw signal envelope and indicate average
            //         (u) => {
            //           const ctx = u.ctx;
            //           const yMax = u.valToPos(signalEnvelopeMaxRef.current, 'y', true);
            //           const yAvg = u.valToPos(signalEnvelopeAvgRef.current, 'y', true);
            //           const yMin = u.valToPos(signalEnvelopeMinRef.current, 'y', true);
            //           let txt = signalEnvelopeAvgRef.current.toFixed(2);
            //           let txtWidth = ctx.measureText(txt).width;
            //           const x = u.bbox.left + u.bbox.width + 20;

            //           ctx.font = '24px sans-serif';
            //           ctx.fillStyle = colorHexToRGBA(ARYBALLE_COLOR_GRAY_DARK, 1);
            //           ctx.fillText(signalEnvelopeAvgRef.current.toFixed(2), x + txtWidth + 20, yAvg);

            //           // ctx.canvas.width = ctx.canvas.width + txtWidth + 20

            //           let bracketWidth = 5;
            //           let pointerWidth = 10;

            //           ctx.strokeStyle = colorHexToRGBA(ARYBALLE_COLOR_GRAY_DARK, 1);
            //           ctx.lineWidth = 2;
            //           ctx.setLineDash([1, 0]);
            //           ctx.beginPath();
            //           ctx.moveTo(x, yMax);
            //           ctx.lineTo(x + bracketWidth, yMax);
            //           ctx.lineTo(x + bracketWidth, yAvg);
            //           ctx.lineTo(x + bracketWidth + pointerWidth, yAvg);
            //           ctx.moveTo(x + bracketWidth, yAvg);
            //           ctx.lineTo(x + bracketWidth, yMin);
            //           ctx.lineTo(x, yMin);
            //           ctx.stroke();
            //         },
            //         // draw odor presence threshold
            //         // (u) => {
            //         //     if (!isOdorPresentRef.current) {
            //         //         return
            //         //     }
            //         //     const ctx = u.ctx
            //         //     const x0 = u.bbox.left
            //         //     const x1 = u.bbox.left + u.bbox.width
            //         //     const y = u.valToPos(odorPresenceThresholdLevelRef.current, "y", true)
            //         //     ctx.strokeStyle = "rgba(255, 0, 0, 1)"
            //         //     ctx.lineWidth = 2
            //         //     ctx.setLineDash([10, 5]);
            //         //     ctx.beginPath()
            //         //     ctx.moveTo(x0, y)
            //         //     ctx.lineTo(x1, y)
            //         //     ctx.stroke()
            //         // },
            //         // draw odor presence "corridor"
            //         (u) => {
            //           if (!isOdorPresentRef.current) {
            //             return;
            //           }
            //           const ctx = u.ctx;
            //           const x0 = u.bbox.left;
            //           const x1 = u.bbox.left + u.bbox.width;
            //           const y0 = u.valToPos(Math.max(maxOdorPresentValue.current, signalEnvelopeMaxRef.current), 'y', true);
            //           const y1 = u.valToPos(Math.max(maxOdorPresentValue.current * DEFAULT_ODOR_PRESENCE_DEACTIVATION_PERCENT_OF_MAX_VALUE, odorPresenceThresholdLevelRef.current), 'y', true);
            //           ctx.fillStyle = colorHexToRGBA(ARYBALLE_COLOR_CYAN, 0.2);
            //           ctx.strokeStyle = colorHexToRGBA(ARYBALLE_COLOR_CYAN, 1);
            //           ctx.lineWidth = 3;
            //           ctx.setLineDash([10, 5]);
            //           ctx.beginPath();
            //           ctx.moveTo(x0, y0);
            //           ctx.lineTo(x1, y0);
            //           ctx.moveTo(x1, y1);
            //           ctx.lineTo(x0, y1);
            //           ctx.fillRect(x0, y1, x1 - x0, y0 - y1);
            //           ctx.stroke();
            //         },
            //         // draw odor presence start and stop timestamps
            //         (u) => {
            //           const ctx = u.ctx;
            //           ctx.strokeStyle = colorHexToRGBA(ARYBALLE_COLOR_CYAN, 1);
            //           ctx.lineWidth = 3;
            //           ctx.setLineDash([10, 5]);
            //           const yBot = u.bbox.top + u.bbox.height;
            //           const yTop = u.bbox.top;
            //           if (odorPresentStartTimestampRef.current > 0) {
            //             const startX = u.valToPos(odorPresentStartTimestampRef.current / 1000, 'x', true);
            //             ctx.beginPath();
            //             ctx.moveTo(startX, yBot);
            //             ctx.lineTo(startX, yTop);
            //             ctx.stroke();
            //           }
            //           if (odorPresentStopTimestampRef.current > 0) {
            //             const stopX = u.valToPos(odorPresentStopTimestampRef.current / 1000, 'x', true);
            //             ctx.beginPath();
            //             ctx.moveTo(stopX, yBot);
            //             ctx.lineTo(stopX, yTop);
            //             ctx.stroke();
            //           }
            //           if (odorPresentStartTimestampRef.current > 0 && odorPresentStopTimestampRef.current > 0) {
            //             const startX = u.valToPos(odorPresentStartTimestampRef.current / 1000, 'x', true);
            //             const stopX = u.valToPos(odorPresentStopTimestampRef.current / 1000, 'x', true);
            //             ctx.fillStyle = colorHexToRGBA(ARYBALLE_COLOR_CYAN, 0.2);
            //             ctx.fillRect(startX, yBot, stopX - startX, yTop - yBot);
            //           }
            //         },
            //       ],
            //     },
            //     focus: {
            //       alpha: 0.3,
            //     },
            //     cursor: {
            //       focus: {
            //         prox: 10,
            //       },
            //     },
            //   };
            //   // console.log("sense page: uplot options", opts)
            //   setMziUplotOptions(opts);
            // }

            // transpose mzisTimeseriesRef.current into Ys
            let Ys = [];
            for (let i = 0; i < nDims; i++) {
              let Y = [];
              for (let j = 0; j < finalMZIsSeries.length; j++) {
                Y[j] = finalMZIsSeries[j][i];
              }
              Ys.push(Y);
            }
            Ys.push(avgSeries);
            let data: uPlot.AlignedData = [X, ...Ys];
            setMziUplotData(data);
            setFpsUplotData([X, rawFpsTimeseriesRef.current, decimatedFpsTimeseriesRef.current, X.map(() => DEFAULT_RAW_FPS), X.map(() => DEFAULT_PLOT_DECIMATED_FPS)]);
            continue;
          }
        }
        // console.log('processed nFramesOnOneMutexLock', nFramesOnOneMutexLock)
        release();
      })
      .catch((e: any) => {
        console.log('sense page: could not acquire mutex', e);
        messageQueueMutexRef.current.cancel();
        messageQueueMutexRef.current.release();
      });
    return () => {
      messageQueueMutexRef.current.cancel();
      messageQueueMutexRef.current.release();
    };
  }, [refkitMessages]);

  useEffect(() => {
    const constructDeviceValue = async () => {
      let commonName = thimphuFspMetadata?.marketingName;
      if (commonName === undefined || commonName === null) {
        commonName = '';
      }
      let shellSerial = thimphuFspMetadata?.shellSerial;
      if (shellSerial === undefined || shellSerial === null) {
        shellSerial = '';
      }
      let coreSensorSerial = thimphuFspMetadata?.coreSensorSerial;
      if (coreSensorSerial === undefined || coreSensorSerial === null) {
        coreSensorSerial = '';
      }
      let fwVersion = refkitVersion?.fwVersion;
      if (fwVersion === undefined || fwVersion === null) {
        throw new Error('fw version is undefined');
      }
      let hwVersion = refkitVersion?.hwVersion;
      if (hwVersion === undefined || hwVersion === null) {
        throw new Error('hw version is undefined');
      }
      let cameraExposure = refkitConfig?.cameraExposure;
      if (cameraExposure === undefined || cameraExposure === null) {
        throw new Error('camera exposure is undefined');
      }
      let spotsgrid = currentSpotsgrid1d;
      if (spotsgrid === undefined || spotsgrid === null) {
        throw new Error('spotsgrid is undefined');
      }
      let _deviceValue = {
        commonName,
        shellSerial,
        coreSensorSerial,
        fwVersion,
        hwVersion,
        cameraExposure,
        spotsgrid,
      };
      console.log('sense page: constructed device value', _deviceValue);
      return _deviceValue;
    };
    constructDeviceValue()
      .then((_deviceValue) => {
        setDeviceValue(_deviceValue);
      })
      .catch((e: any) => {
        console.log('sense page: could not construct device', e);
      });
  }, [refkitVersion, refkitConfig, thimphuFspMetadata, currentSpotsgrid1d]);

  const constructSignatureAndRecognize = (idxStart?: number) => {
    if (idxStart === undefined) {
      idxStart = -DEFAULT_PLOT_DECIMATED_FPS * DEFAULT_IMMEDIATE_RECOGNITION_BACKWARD_WINDOW_SEC;
    }
    let sectionMZIs = decimatedMZISeriesRef.current.slice(idxStart);
    let sectionMZIsSpans = transpose(sectionMZIs);

    if (!currentSpotsgrid1d) {
      console.log('sense page: spotsgrid is empty');
      return;
    }

    // signature with no baseline substraction, simple analyte mean
    let _signature = sectionMZIsSpans.map((mzis) => mean(mzis));
    let excludedSignature: number[] = [];
    let excludedSpotsgrid1d: number[] = [];
    for (let i = 0; i < currentSpotsgrid1d.length; i++) {
      let sensorInt = currentSpotsgrid1d[i];
      if (sensorInt > 1) {
        excludedSignature.push(_signature[i]);
        excludedSpotsgrid1d.push(sensorInt);
      }
    }

    let finalSignature: number[] = [];
    let finalSpotsgrid1d: number[] = [];

    // always aggregate by common spot name
    let [aggregatedSignature, aggregatedSpotsgrid1d] = aggregateSignature(excludedSignature, excludedSpotsgrid1d);
    finalSignature = aggregatedSignature;
    finalSpotsgrid1d = aggregatedSpotsgrid1d;

    let [sortedFinaleSignature, sortedFinalSpotsgrid1d] = sortSignature(finalSpotsgrid1d, finalSignature);
    let normalizedSortedAggregatedSignature = normalizeL2(sortedFinaleSignature);

    setQuestionningSignature(normalizedSortedAggregatedSignature);
    setQuestionningSpotsgrid1d(sortedFinalSpotsgrid1d);
  };

  useEffect(() => {
    if (questioningState !== QuestioningState.OdorAnalysis) return;
    let _model = loadModel();
    setCurrentModel(_model);
  }, [questioningState]);

  useEffect(() => {
    if (questioningState !== QuestioningState.OdorAnalysis) return;
    if (questionningSignature === null) {
      console.log('questioning result widget: null signature');
      setQuestionningResult(null);
      return;
    }
    if (questionningSpotsgrid1d === null) {
      console.log('questioning result widget: null spotsgrid1d');
      return;
    }
    if (currentModel === null) {
      console.log('questioning result widget: received signature upon null model');
      return;
    }

    let [label, point] = classifySignature(currentModel.groupedScaledEllipses, currentModel.pcaEigenvectors, questionningSignature);

    let _questionningResult: QuestionningResult = {
      label: label,
      point: point,
    };
    setQuestionningResult(_questionningResult);
    setQuestioningState(QuestioningState.OdorDisplay);
  }, [questionningSignature, currentModel]);

  useEffect(() => {
    if (questioningState !== QuestioningState.OdorDisplay) return;
    setTimeout(() => {
      setQuestioningState(QuestioningState.SensorCleaning);
    }, 10000);
  }, [questioningState, questionningSignature]);

  useEffect(() => {
    if (questioningState !== QuestioningState.SensorCleaning) return;
    if (Math.round(100 * Number(signalEnvelopeAvgRef.current)) / 100 <= 0.2) setQuestioningState(QuestioningState.BaselineRecording);
  }, [questioningState]);

  return (
    <>
      {questioningState === QuestioningState.BaselineRecording && <BaselineRecording signalEnvelopeAvgRef={signalEnvelopeAvgRef} />}
      {questioningState === QuestioningState.AnalyteRecording && <AnalyteRecording signalEnvelopeAvgRef={signalEnvelopeAvgRef} />}
      {questioningState === QuestioningState.OdorAnalysis && <OdorAnalysis />}
      {questioningState === QuestioningState.OdorDisplay && <OdorDisplay result={questionningResult} />}
      {questioningState === QuestioningState.SensorCleaning && <SensorCleaning signalEnvelopeAvgRef={signalEnvelopeAvgRef} />}
    </>
  );
};

export default SerialOdorIdentification;
