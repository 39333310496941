import { RecordValue, RecordValueWithSensograms } from '../idb/idb';
import { mean, transpose } from './utils';

type signal = number[][]; // [sensor][time]
type signature = number[]; // [sensor]

const _extractSignature = (a: signal, baselineBounds: number[], plateauBounds: number[]): signature => {
  const baselineMeans = transpose(a.slice(...baselineBounds)).map((sig) => mean(sig));
  const analyteMeans = transpose(a.slice(...plateauBounds)).map((sig) => mean(sig));
  if (baselineMeans.length !== analyteMeans.length) {
    throw Error(`Number of sensors in baseline and plateau do not correspond: ${baselineMeans.length} vs ${analyteMeans.length}`);
  }
  const signature: number[] = [];
  for (let i = 0; i < analyteMeans.length; i++) {
    signature.push(analyteMeans[i] - baselineMeans[i]);
  }
  return signature;
};

export const normalizeL2 = (a: number[]): number[] => {
  let d = a.map((cur) => Math.pow(cur, 2));
  let dd = d.reduce((acc, cur) => (acc += cur), 0);
  let ddd = Math.sqrt(dd);
  return a.map((cur) => cur / ddd);
};

export const computeSignature = (record: RecordValueWithSensograms) => {
  if (record.baselineStart === undefined || record.baselineEnd === undefined) {
    throw Error('Baseline bounds are undefined');
  }
  if (record.analyteStart === undefined || record.analyteEnd === undefined) {
    throw Error('Analyte bounds are undefined');
  }
  const baselineBounds = [record.baselineStart, record.baselineEnd];
  const analyteBounds = [record.analyteStart, record.analyteEnd];
  const a = record.sensogramSeries;
  const signature = _extractSignature(a, baselineBounds, analyteBounds);
  return signature;
};

/*
Sort each signature in an array in spotName ASC order
*/
export const sortSignature = (spotsgrid1d: number[], signature: signature): [number[], signature] => {
  const spotsWithValues: [number, number][] = [];
  for (let i = 0; i < signature.length; i++) {
    spotsWithValues.push([spotsgrid1d[i], signature[i]]);
  }
  spotsWithValues.sort();
  let sortedSpotsgrid1d = spotsWithValues.map((e) => e[0]);
  let sortedSignature = spotsWithValues.map((e) => e[1]);

  return [sortedSignature, sortedSpotsgrid1d];
};

export const getAggregatedSpotsgridIndicesMap = (spotsgrid1d: number[]): Record<number, number[]> => {
  let _aggregationIndicesMap: Record<number, number[]> = {};
  if (!spotsgrid1d) {
    console.log('sense page: spotsgrid is empty');
    return _aggregationIndicesMap;
  }
  // Aggregate MZIs by peptide
  for (let i = 0; i < spotsgrid1d.length; i++) {
    let aggKey = spotsgrid1d[i];
    if (_aggregationIndicesMap[aggKey] === undefined) {
      _aggregationIndicesMap[aggKey] = [];
    }
    _aggregationIndicesMap[aggKey].push(i);
  }
  return _aggregationIndicesMap;
};

export const aggregateSignature = (signature: signature, spotsgrid1d: number[]): [signature, number[]] => {
  const _aggregationIndicesMap = getAggregatedSpotsgridIndicesMap(spotsgrid1d);
  const aggregatedSignature: signature = [];
  const aggregatedSpotsgrid1d: number[] = [];
  for (const [aggKey, indices] of Object.entries(_aggregationIndicesMap)) {
    aggregatedSignature.push(mean(indices.map((i) => signature[i])));
    aggregatedSpotsgrid1d.push(parseInt(aggKey));
  }
  return [aggregatedSignature, aggregatedSpotsgrid1d];
};

export const aggregateSensogramSpans = (sensogramSpans: number[][], spotsgrid1d: number[]): [number[][], number[]] => {
  const _aggregationIndicesMap = getAggregatedSpotsgridIndicesMap(spotsgrid1d);
  const aggregatedSensogramSpans: number[][] = [];
  const aggregatedSpotsgrid1d: number[] = [];
  for (const [aggKey, indices] of Object.entries(_aggregationIndicesMap)) {
    let aggregatedSensogramSpan: number[] = [];
    for (let i = 0; i < sensogramSpans[0].length; i++) {
      aggregatedSensogramSpan.push(mean(indices.map((j) => sensogramSpans[j][i])));
    }
    aggregatedSensogramSpans.push(aggregatedSensogramSpan);
    aggregatedSpotsgrid1d.push(parseInt(aggKey));
  }
  return [aggregatedSensogramSpans, aggregatedSpotsgrid1d];
};
