import { useEffect, useRef, useState } from 'react';
import BaselineRecording from './BaselineRecording';
import AnalyteRecording from './AnalyteRecording';
import OdorAnalysis from './OdorAnalysis';
import OdorDisplay from './OdorDisplay';
import SensorCleaning from './SensorCleaning';
import { useMessageContext } from '../../reducers/messageContext';
import { DeviceValue, RecordKey, commitSensogramPartition } from '../../idb/idb';
import { Mutex, MutexInterface, withTimeout } from 'async-mutex';
import { CSM_PROTOCOL_COMMAND_TYPE, CSM_PROTOCOL_EVENT_TYPE, parseBiosensorsSignalEvent, parseEventPayload } from '../../serial/csm';
import { v4 as uuidv4 } from 'uuid';
import { loadModel, loadSpotsGrid1D } from '../../localStorage';
import { DEFAULT_IMMEDIATE_RECOGNITION_BACKWARD_WINDOW_SEC, DEFAULT_PLOT_DECIMATED_FPS, DEFAULT_RAW_FPS, DEFAULT_STORAGE_DECIMATED_FPS, IDB_PARTITION_WINDOW_SIZE, PLOT_WINDOW_SIZE } from '../../constants';
import { mean, standardDeviation, transpose } from '../../analysis/utils';
import { DEFAULT_ODOR_PRESENCE_DEACTIVATION_PERCENT_OF_MAX_VALUE } from '../../serial/constants';
import { DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE, PEPTIDE_COLOR_MAP_VDW } from '../../utils';
import { aggregateSignature, normalizeL2, sortSignature } from '../../analysis/compute';
import { ModelType } from '../../byteio/model';
import { QuestionningResult } from '../../types';
import { classifySignature } from '../../analysis/classifier';

type BleOdorIdentificationProps = {};
enum QuestioningState {
  SensorCleaning = 'SensorCleaning',
  BaselineRecording = 'BaselineRecording',
  AnalyteRecording = 'AnalyteRecording',
  OdorAnalysis = 'OdorAnalysis',
  OdorDisplay = 'OdorDisplay',
}

enum SenseMode {
  Recording,
  Questionning,
}

const BleOdorIdentification: React.FC<BleOdorIdentificationProps> = () => {
  const [questioningState, setQuestioningState] = useState<QuestioningState>(QuestioningState.BaselineRecording);

  const [questionningSignature, setQuestionningSignature] = useState<number[] | null>(null);
  const [questionningSpotsgrid1d, setQuestionningSpotsgrid1d] = useState<number[] | null>(null);

  const { csmMessages, csmIsConnected, consumeCSMMessage, clearCSMMessages, addCSMCommand } = useMessageContext();

  const [mziUplotOptions, setMziUplotOptions] = useState<uPlot.Options | null>(null);

  const [mziUplotData, setMziUplotData] = useState<uPlot.AlignedData>([]);
  const [fpsUplotData, setFpsUplotData] = useState<uPlot.AlignedData>([]);

  const mziTargetRef = useRef<HTMLDivElement>(null);
  const mziUplotRef = useRef<uPlot | null>(null);
  const mziTooltipRef = useRef<HTMLDivElement>(null);

  const fpsTargetRef = useRef<HTMLDivElement>(null);
  const fpsUplotRef = useRef<uPlot | null>(null);

  const firstMZIsRef = useRef<number[] | null>(null);
  const previousMZIsRef = useRef<number[] | null>(null);
  const KsRef = useRef<number[] | null>(null);

  const noizeLevelRef = useRef<number>(0);
  const isOdorPresentRef = useRef<boolean>(false);
  const odorPresenceThresholdLevelRef = useRef<number>(0);
  const maxOdorPresentValue = useRef<number>(0);
  const odorPresentStartTimestampRef = useRef<number>(0);
  const odorPresentStopTimestampRef = useRef<number>(0);
  const odorPresentLastRecognitionTimestampRef = useRef<number>(0);
  const signalEnvelopeMinRef = useRef<number>(0);
  const signalEnvelopeMaxRef = useRef<number>(0);
  const signalEnvelopeAvgRef = useRef<number>(0);

  const decimatedMZISeriesRef = useRef<number[][]>([]);
  const rawMZISeriesRef = useRef<number[][]>([]);

  const decimatedTimestampSeriesRef = useRef<number[]>([]);
  const rawTimestampSeriesRef = useRef<number[]>([]);

  const decimatedMZIPartitionSeriesRef = useRef<number[][]>([]);
  const decimatedTimestampPartitionSeriesRef = useRef<number[]>([]);

  const decimatedFpsTimeseriesRef = useRef<number[]>([]);
  const rawFpsTimeseriesRef = useRef<number[]>([]);

  const [rawFps, setRawFps] = useState<number>(0);
  const [decimatedFps, setDecimatedFps] = useState<number>(0);

  const startTickRef = useRef<number>(0);
  const lastDecimationTickRef = useRef<number>(0);

  const [currentSpotsgrid1d, setCurrentSpotsgrid1d] = useState<number[] | null>(null);
  const [aggregatedIndicesMap, setAggregatedIndicesMap] = useState<Record<number, number[]>>({});

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const recordKeyRef = useRef<RecordKey | null>(null);

  const [isSensing, setIsSensing] = useState<boolean>(true);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [recordStartTimestamp, setRecordStartTimestamp] = useState<number>(0);

  const [deviceValue, setDeviceValue] = useState<DeviceValue | null>(null);
  const [isRecordModalOpen, setIsRecordModalOpen] = useState<boolean>(false);

  const [shouldAggregate, setShouldAggregate] = useState<boolean>(true);
  const [showDebugInfo, setShowDebugInfo] = useState<boolean>(false);
  const [shouldRedraw, setShouldRedraw] = useState<boolean>(false);
  const [pinLastQuestionningResult, setPinLastQuestionningResult] = useState<boolean>(true);

  const [senseMode, setSenseMode] = useState<SenseMode>(SenseMode.Recording);

  const messageQueueMutexRef = useRef<MutexInterface>(withTimeout(new Mutex(), 300));

  const [currentModel, setCurrentModel] = useState<ModelType | null>(null);
  const [questionningResult, setQuestionningResult] = useState<QuestionningResult | null>(null);

  useEffect(() => {
    clearCSMMessages();
    addCSMCommand({
      id: uuidv4().toString(),
      message: {
        CmdType: CSM_PROTOCOL_COMMAND_TYPE.StartSampling,
      },
    });
    setIsLoading(false);
    return () => {
      addCSMCommand({
        id: uuidv4().toString(),
        message: {
          CmdType: CSM_PROTOCOL_COMMAND_TYPE.StopSampling,
        },
      });
      setIsLoading(true);
    };
  }, []);

  useEffect(() => {
    let _spotsgrid1d = loadSpotsGrid1D();
    if (!_spotsgrid1d) {
      console.log('sense page: spotsgrid1d is empty');
      return;
    }
    setCurrentSpotsgrid1d(_spotsgrid1d);
    // Aggregate MZIs by peptide
    let _aggregationIndicesMap: Record<number, number[]> = {};
    for (let i = 0; i < _spotsgrid1d.length; i++) {
      let aggKey = _spotsgrid1d[i];
      if (aggKey < 0) {
        continue;
      }
      if (_aggregationIndicesMap[aggKey] === undefined) {
        _aggregationIndicesMap[aggKey] = [];
      }
      _aggregationIndicesMap[aggKey].push(i);
    }
    setAggregatedIndicesMap(_aggregationIndicesMap);
    // console.log("sense page: _aggregationIndicesMap", _aggregationIndicesMap)
  }, []);

  useEffect(() => {
    if (csmMessages.length === 0) {
      return;
    }
    if (messageQueueMutexRef.current.isLocked()) {
      return;
    }
    let t = Date.now();
    // console.log("sense page: acquiring mutex..")
    messageQueueMutexRef.current
      .acquire()
      .then((release) => {
        // console.log("sense page: acquired mutex in ", Date.now() - t, "ms")
        let nFramesOnOneMutexLock = 0;
        for (let message of csmMessages) {
          if (message.message.Type !== CSM_PROTOCOL_EVENT_TYPE.BiosensorsSignalEvent) {
            console.log('sense page: csm ble message is not a biosensors signal event', message.message);
            consumeCSMMessage(message.id);
            continue;
          } else {
            nFramesOnOneMutexLock++;
            consumeCSMMessage(message.id);

            // load the spotsgrid
            if (!currentSpotsgrid1d) {
              console.log('sense page: spotsgrid is empty');
              return;
            }

            let tick = startTickRef.current;
            // console.log("sense page: ts", ts)

            let mzis: number[] | null = null;
            try {
              let eventPayload = parseEventPayload(message.message.Payload);
              mzis = parseBiosensorsSignalEvent(eventPayload.Data);
              // tick = startTickRef.current + eventPayload.Tick
              tick = message.ts;
            } catch (e: any) {
              console.log('sense page: could not parse biosensors signal event', e.message);
            }
            if (mzis === null) {
              continue;
            }
            previousMZIsRef.current = mzis;

            rawMZISeriesRef.current.push(mzis);
            rawTimestampSeriesRef.current.push(tick);

            if (tick - lastDecimationTickRef.current < 1000 / DEFAULT_PLOT_DECIMATED_FPS) {
              continue;
            }
            lastDecimationTickRef.current = tick;

            // if (rawMZISeriesRef.current.length < PLOT_DECIMATION_WINDOW_SIZE) {
            //     continue;
            // }

            decimatedTimestampSeriesRef.current.push(tick);
            // console.log("sense page: decimated timestamp timeseries", decimatedTimestampTimeseriesRef.current)

            if (decimatedTimestampSeriesRef.current.length > PLOT_WINDOW_SIZE) {
              decimatedTimestampSeriesRef.current.shift();
            }

            // calculate raw timestamp intervals
            let rawTimestampIntervals: number[] = [];
            for (let i = 0; i < rawTimestampSeriesRef.current.length - 1; i++) {
              rawTimestampIntervals[i] = rawTimestampSeriesRef.current[i + 1] - rawTimestampSeriesRef.current[i];
            }
            rawTimestampSeriesRef.current = [];
            let rawTimestampInterval = rawTimestampIntervals.reduce((a, b) => a + b, 0) / rawTimestampIntervals.length;
            let rawFps = 1000 / rawTimestampInterval;
            setRawFps(rawFps);

            if (!isNaN(rawFps)) {
              rawFpsTimeseriesRef.current.push(rawFps);
            }
            if (rawFpsTimeseriesRef.current.length > PLOT_WINDOW_SIZE) {
              rawFpsTimeseriesRef.current.shift();
            }

            // calculate decimated timestamp intervals
            let decimatedTimestampIntervals: number[] = [];
            for (let i = 0; i < decimatedTimestampSeriesRef.current.length - 1; i++) {
              decimatedTimestampIntervals[i] = decimatedTimestampSeriesRef.current[i + 1] - decimatedTimestampSeriesRef.current[i];
            }
            let decimatedTimestampInterval = decimatedTimestampIntervals.reduce((a, b) => a + b, 0) / decimatedTimestampIntervals.length;
            // console.log("sense page: decimated timestamp interval", decimatedTimestampInterval, decimatedTimestampIntervals)
            let decimatedFps = 1000 / decimatedTimestampInterval;
            setDecimatedFps(decimatedFps);

            if (!isNaN(decimatedFps)) {
              decimatedFpsTimeseriesRef.current.push(decimatedFps);
            }
            if (decimatedFpsTimeseriesRef.current.length > PLOT_WINDOW_SIZE) {
              decimatedFpsTimeseriesRef.current.shift();
            }

            // decimate MZIs by averaging over DECIMATION_WINDOW_SIZE
            let decimatedMzis: number[] = [];
            for (let i = 0; i < currentSpotsgrid1d.length; i++) {
              let sum = 0;
              for (let j = 0; j < rawMZISeriesRef.current.length; j++) {
                sum += rawMZISeriesRef.current[j][i];
              }
              decimatedMzis[i] = sum / rawMZISeriesRef.current.length;
            }
            rawMZISeriesRef.current = [];

            // subtract first mzis and save them
            if (firstMZIsRef.current === null) {
              firstMZIsRef.current = [...decimatedMzis];
              // console.log("sense page: first mzis is null. setting to", firstMZIsRef.current)
            }
            for (let i = 0; i < currentSpotsgrid1d.length; i++) {
              decimatedMzis[i] -= firstMZIsRef.current[i];
            }

            decimatedMZISeriesRef.current.push(decimatedMzis);
            if (decimatedMZISeriesRef.current.length > PLOT_WINDOW_SIZE) {
              decimatedMZISeriesRef.current.shift();
            }

            decimatedTimestampPartitionSeriesRef.current.push(tick);
            decimatedMZIPartitionSeriesRef.current.push(decimatedMzis); // save non-aggreated mzis regardless of shouldAggregate

            if (decimatedMZIPartitionSeriesRef.current.length >= IDB_PARTITION_WINDOW_SIZE) {
              if (isRecording && recordKeyRef.current !== null) {
                // average decimate decimatedMZIPartitionSeriesRef by 2 for storage
                // (storage decimation is half the plotting one)
                let storageDecimatedMZIPartitionSeries: number[][] = [];
                let storageDecimatedTimestampPartitionSeries: number[] = [];
                let storageDecimationFactor = Math.floor(DEFAULT_PLOT_DECIMATED_FPS / DEFAULT_STORAGE_DECIMATED_FPS);
                for (let i = 0; i < decimatedMZIPartitionSeriesRef.current.length; i += storageDecimationFactor) {
                  let storageDecimatedMzis: number[] = [];
                  for (let j = 0; j < currentSpotsgrid1d.length; j++) {
                    let sum = 0;
                    for (let ii = 0; ii < storageDecimationFactor; ii++) {
                      sum += decimatedMZIPartitionSeriesRef.current[i + ii][j];
                    }
                    storageDecimatedMzis[j] = sum / storageDecimationFactor;
                  }
                  storageDecimatedMZIPartitionSeries.push(storageDecimatedMzis);
                  // timestamps are just subsampled (not averaged)
                  storageDecimatedTimestampPartitionSeries.push(decimatedTimestampPartitionSeriesRef.current[i]);
                }
                commitSensogramPartition(recordKeyRef.current, storageDecimatedMZIPartitionSeries, storageDecimatedTimestampPartitionSeries)
                  .then(() => {
                    console.log('sense page: saved partition');
                  })
                  .catch((e: any) => {
                    console.log('sense page: could not save partition', e);
                  });
              }
              decimatedMZIPartitionSeriesRef.current = [];
              decimatedTimestampPartitionSeriesRef.current = [];
            }

            let seriesLabels: number[] = [];
            if (shouldAggregate) {
              seriesLabels = Object.keys(aggregatedIndicesMap).map((aggKey) => parseInt(aggKey));
            } else {
              seriesLabels = [...currentSpotsgrid1d];
            }
            let nDims = seriesLabels.length;

            // build mzi uplot data
            let X = decimatedTimestampSeriesRef.current.map((ts) => ts / 1000);

            // aggregate mzis timeseries (frame by frame) if needed
            let finalMZIsSeries: number[][] = [];
            if (shouldAggregate) {
              for (let j = 0; j < decimatedMZISeriesRef.current.length; j++) {
                let finalMZIs: number[] = [];
                for (let aggKey in aggregatedIndicesMap) {
                  let aggIndices = aggregatedIndicesMap[aggKey];
                  let sum = 0;
                  for (let i = 0; i < aggIndices.length; i++) {
                    sum += decimatedMZISeriesRef.current[j][aggIndices[i]];
                  }
                  finalMZIs.push(sum / aggIndices.length);
                }
                finalMZIsSeries.push(finalMZIs);
              }
            } else {
              finalMZIsSeries = decimatedMZISeriesRef.current;
            }

            if (finalMZIsSeries.length < 2) {
              continue;
            }

            //
            let lastFrame: number[] = finalMZIsSeries[finalMZIsSeries.length - 1];
            let lastFrameSum: number = 0;
            signalEnvelopeMinRef.current = 1e6;
            signalEnvelopeMaxRef.current = -1e6;
            for (let i = 0; i < lastFrame.length; i++) {
              if (lastFrame[i] < signalEnvelopeMinRef.current) {
                signalEnvelopeMinRef.current = lastFrame[i];
              }
              if (lastFrame[i] > signalEnvelopeMaxRef.current) {
                signalEnvelopeMaxRef.current = lastFrame[i];
              }
              lastFrameSum += lastFrame[i];
            }
            signalEnvelopeAvgRef.current = lastFrameSum / lastFrame.length;
            let previousFrameMean: number = mean(finalMZIsSeries[finalMZIsSeries.length - 2]);

            let avgSeries: number[] = [];
            for (let i = 0; i < finalMZIsSeries.length; i++) {
              avgSeries.push(mean(finalMZIsSeries[i]));
            }

            if (!isOdorPresentRef.current && noizeLevelRef.current > 0 && signalEnvelopeAvgRef.current > noizeLevelRef.current) {
              odorPresenceThresholdLevelRef.current = mean([previousFrameMean, signalEnvelopeAvgRef.current]);
              odorPresentStartTimestampRef.current = decimatedTimestampSeriesRef.current[decimatedTimestampSeriesRef.current.length - 1];
              odorPresentStopTimestampRef.current = 0;
              odorPresentLastRecognitionTimestampRef.current = Date.now();
              isOdorPresentRef.current = true;
              setQuestioningState(QuestioningState.AnalyteRecording);
            }

            if (isOdorPresentRef.current && signalEnvelopeAvgRef.current < Math.max(maxOdorPresentValue.current * DEFAULT_ODOR_PRESENCE_DEACTIVATION_PERCENT_OF_MAX_VALUE, odorPresenceThresholdLevelRef.current)) {
              isOdorPresentRef.current = false;
              maxOdorPresentValue.current = 0;
              odorPresenceThresholdLevelRef.current = 0;
              odorPresentStopTimestampRef.current = decimatedTimestampSeriesRef.current[decimatedTimestampSeriesRef.current.length - 1];
              if (!pinLastQuestionningResult) {
                setQuestionningSignature(null);
              } else {
                constructSignatureAndRecognize();
              }
              setQuestioningState(QuestioningState.OdorAnalysis);
            }

            if (!isOdorPresentRef.current) {
              let noizeSeries = avgSeries.slice(-4 * DEFAULT_PLOT_DECIMATED_FPS, -1 * DEFAULT_PLOT_DECIMATED_FPS);
              noizeLevelRef.current = mean(noizeSeries) + standardDeviation(noizeSeries) * 12;
            }

            if (isOdorPresentRef.current) {
              maxOdorPresentValue.current = Math.max(maxOdorPresentValue.current, signalEnvelopeAvgRef.current);
            }

            if (isOdorPresentRef.current && Date.now() - odorPresentLastRecognitionTimestampRef.current > 250) {
              constructSignatureAndRecognize();
              odorPresentLastRecognitionTimestampRef.current = Date.now();
            }

            // transpose mzisTimeseriesRef.current into Ys
            let Ys = [];
            for (let i = 0; i < nDims; i++) {
              let Y = [];
              for (let j = 0; j < finalMZIsSeries.length; j++) {
                Y[j] = finalMZIsSeries[j][i];
              }
              Ys.push(Y);
            }
            Ys.push(avgSeries);
            let data: uPlot.AlignedData = [X, ...Ys];
            setMziUplotData(data);
            setFpsUplotData([X, rawFpsTimeseriesRef.current, decimatedFpsTimeseriesRef.current, X.map(() => DEFAULT_RAW_FPS), X.map(() => DEFAULT_PLOT_DECIMATED_FPS)]);
            continue;
          }
        }
        // console.log('processed nFramesOnOneMutexLock', nFramesOnOneMutexLock)
        release();
      })
      .catch((e: any) => {
        console.log('sense page: could not acquire mutex', e);
        messageQueueMutexRef.current.cancel();
        messageQueueMutexRef.current.release();
      });
    return () => {
      messageQueueMutexRef.current.cancel();
      messageQueueMutexRef.current.release();
    };
  }, [csmMessages]);

  useEffect(() => {
    const constructDeviceValue = async () => {
      let commonName = 'Neose CSM BLE';
      if (commonName === undefined || commonName === null) {
        commonName = '';
      }
      let shellSerial = '';
      let coreSensorSerial = '';
      let fwVersion = '';
      let hwVersion = '';
      let cameraExposure = 0;

      let spotsgrid = currentSpotsgrid1d;
      if (spotsgrid === undefined || spotsgrid === null) {
        throw new Error('spotsgrid is undefined');
      }

      let _deviceValue = {
        commonName,
        shellSerial,
        coreSensorSerial,
        fwVersion,
        hwVersion,
        cameraExposure,
        spotsgrid,
      };
      console.log('sense page: constructed device value', _deviceValue);
      return _deviceValue;
    };
    constructDeviceValue()
      .then((_deviceValue) => {
        setDeviceValue(_deviceValue);
      })
      .catch((e: any) => {
        console.log('sense page: could not construct device', e);
      });
  }, [currentSpotsgrid1d]);

  useEffect(() => {
    if (questioningState !== QuestioningState.OdorAnalysis) return;
    let _model = loadModel();
    setCurrentModel(_model);
  }, [questioningState]);

  useEffect(() => {
    if (questioningState !== QuestioningState.OdorAnalysis) return;
    if (questionningSignature === null) {
      console.log('questioning result widget: null signature');
      setQuestionningResult(null);
      return;
    }
    if (questionningSpotsgrid1d === null) {
      console.log('questioning result widget: null spotsgrid1d');
      return;
    }
    if (currentModel === null) {
      console.log('questioning result widget: received signature upon null model');
      return;
    }

    let [label, point] = classifySignature(currentModel.groupedScaledEllipses, currentModel.pcaEigenvectors, questionningSignature);

    let _questionningResult: QuestionningResult = {
      label: label,
      point: point,
    };
    setQuestionningResult(_questionningResult);
    setQuestioningState(QuestioningState.OdorDisplay);
  }, [questionningSignature, currentModel]);

  useEffect(() => {
    if (questioningState !== QuestioningState.OdorDisplay) return;
    setTimeout(() => {
      setQuestioningState(QuestioningState.SensorCleaning);
    }, 10000);
  }, [questioningState, questionningSignature]);

  useEffect(() => {
    if (questioningState !== QuestioningState.SensorCleaning) return;
    if (Math.round(100 * Number(signalEnvelopeAvgRef.current)) / 100 <= 0.2) setQuestioningState(QuestioningState.BaselineRecording);
  }, [questioningState, signalEnvelopeAvgRef.current]);

  const constructSignatureAndRecognize = (idxStart?: number) => {
    if (idxStart === undefined) {
      idxStart = -DEFAULT_PLOT_DECIMATED_FPS * DEFAULT_IMMEDIATE_RECOGNITION_BACKWARD_WINDOW_SEC;
    }
    let sectionMZIs = decimatedMZISeriesRef.current.slice(idxStart);
    let sectionMZIsSpans = transpose(sectionMZIs);

    if (!currentSpotsgrid1d) {
      console.log('sense page: spotsgrid is empty');
      return;
    }

    // signature with no baseline substraction, simple analyte mean
    let _signature = sectionMZIsSpans.map((mzis) => mean(mzis));
    let excludedSignature: number[] = [];
    let excludedSpotsgrid1d: number[] = [];
    for (let i = 0; i < currentSpotsgrid1d.length; i++) {
      let sensorInt = currentSpotsgrid1d[i];
      if (sensorInt > 1) {
        excludedSignature.push(_signature[i]);
        excludedSpotsgrid1d.push(sensorInt);
      }
    }

    let finalSignature: number[] = [];
    let finalSpotsgrid1d: number[] = [];

    // always aggregate by common spot name
    let [aggregatedSignature, aggregatedSpotsgrid1d] = aggregateSignature(excludedSignature, excludedSpotsgrid1d);
    finalSignature = aggregatedSignature;
    finalSpotsgrid1d = aggregatedSpotsgrid1d;

    let [sortedFinaleSignature, sortedFinalSpotsgrid1d] = sortSignature(finalSpotsgrid1d, finalSignature);
    let normalizedSortedAggregatedSignature = normalizeL2(sortedFinaleSignature);

    setQuestionningSignature(normalizedSortedAggregatedSignature);
    setQuestionningSpotsgrid1d(sortedFinalSpotsgrid1d);
  };

  return (
    <>
      {questioningState === QuestioningState.BaselineRecording && <BaselineRecording signalEnvelopeAvgRef={signalEnvelopeAvgRef} />}
      {questioningState === QuestioningState.AnalyteRecording && <AnalyteRecording signalEnvelopeAvgRef={signalEnvelopeAvgRef} />}
      {questioningState === QuestioningState.OdorAnalysis && <OdorAnalysis />}
      {questioningState === QuestioningState.OdorDisplay && <OdorDisplay result={questionningResult} />}
      {questioningState === QuestioningState.SensorCleaning && <SensorCleaning signalEnvelopeAvgRef={signalEnvelopeAvgRef} />}
    </>
  );
};

export default BleOdorIdentification;
