// export const MZI_LENGTH = 64

export const IMAGE_WIDTH = 640;
export const IMAGE_HEIGHT = 400;

export const DEFAULT_RAW_FPS = 60;
export const DEFAULT_PLOT_DECIMATED_FPS = 6; // 1/10 of raw fps
export const DEFAULT_STORAGE_DECIMATED_FPS = 3; // 1/20 of raw fps

export const STORAGE_DECIMATION_WINDOW_SIZE = Math.floor(DEFAULT_RAW_FPS / DEFAULT_STORAGE_DECIMATED_FPS);
export const PLOT_DECIMATION_WINDOW_SIZE = Math.floor(DEFAULT_RAW_FPS / DEFAULT_PLOT_DECIMATED_FPS);

export const PLOT_WINDOW_INTERVAL = 30; // seconds
export const PLOT_WINDOW_SIZE = PLOT_WINDOW_INTERVAL * DEFAULT_PLOT_DECIMATED_FPS;

export const IDB_PARTITION_INTERVAL = 5; // seconds
export const IDB_PARTITION_WINDOW_SIZE = IDB_PARTITION_INTERVAL * DEFAULT_PLOT_DECIMATED_FPS;

export const IDB_DEFAULT_BASELINE_INTERVAL = 3; // seconds
export const IDB_DEFAULT_BASELINE_WINDOW_SIZE = IDB_DEFAULT_BASELINE_INTERVAL * DEFAULT_STORAGE_DECIMATED_FPS;

export const IDB_DEFAULT_ANALYTE_INTERVAL = 5; // seconds
export const IDB_DEFAULT_ANALYTE_WINDOW_SIZE = IDB_DEFAULT_ANALYTE_INTERVAL * DEFAULT_STORAGE_DECIMATED_FPS;

export const DEFAULT_IMMEDIATE_RECOGNITION_BACKWARD_WINDOW_SEC = 2; // seconds

export const DEFAULT_PLOTLY_MARGIN: Plotly.Layout['margin'] = {
  t: 30,
  b: 40,
  l: 40,
  r: 40,
};

export const DEFAULT_PLOTLY_CONFIG: Partial<Plotly.Config> = {
  displaylogo: false,
  responsive: true,
  displayModeBar: true,
  modeBarButtons: [['zoom2d', 'select2d'] as Plotly.ModeBarDefaultButtons[]],
};

export const DEFAULT_PLOTLY_LAYOUT: Partial<Plotly.Layout> = {
  autosize: true,
  showlegend: false,
  margin: DEFAULT_PLOTLY_MARGIN,
};
