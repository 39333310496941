import { PCA } from 'ml-pca';
import { signature } from './definitions';
import { Matrix } from 'ml-matrix';
import { getGroupedMap, sum } from './utils';
import { getGaussianEllipse, scaleEllipsesSigma } from './ellipse';

export const pcaFit = (signatures: signature[]): PCA => new PCA(signatures);

export const pcaTransform = (signatures: signature[], eigvec: number[][], nComponents: number): number[][] => {
  const U = new Matrix(eigvec);
  const dataset = new Matrix(signatures);

  // // Re-center the dataset
  // dataset.subRowVector(dataset.mean('column'))

  // Transform
  const predictions = dataset.mmul(U);

  // Return most significant nComponents
  return predictions.subMatrix(0, predictions.rows - 1, 0, nComponents - 1).to2DArray();
};

export const pcaFitTransform = (signatures: signature[], nComponents: number): number[][] => {
  const pca = pcaFit(signatures);
  return pcaTransform(signatures, pca.getEigenvectors().to2DArray(), nComponents);
};

/*
Analyze the signatures
:param sigmaMultiplier: null - automatic sigma, number - manual sigma
*/
export const performAnalysis = (signatures: signature[], labels: string[]) => {
  // Fit and transform the data to a 2D PCA
  const pca = pcaFit(signatures);

  //Get the eigenvectors of the PCA to send it to watch
  const pcaEigenvectors = pca
    .getEigenvectors()
    .subMatrix(0, pca.getEigenvectors().rows - 1, 0, 1)
    .to2DArray();

  const projections = pcaTransform(signatures, pcaEigenvectors, 2);

  // Regroup projections by label (clusterize) and compute the ellipses
  const groupedProjections: Record<string, number[][]> = getGroupedMap(projections, labels);

  const groupedEllipses: Record<string, number[]> = {};
  Object.entries(groupedProjections).forEach(([label, projs]) => {
    groupedEllipses[label] = getGaussianEllipse(projs);
  });

  // // Scale ellipses with a custom sigma value
  let sigma: number = 1;
  // let groupedScaledEllipses = scaleEllipsesSigma(groupedEllipses, sigma);
  let groupedScaledEllipses = groupedEllipses;

  const pcaExplainedVariance = pca.getExplainedVariance();
  const pcaExplainedVarianceRatio = pcaExplainedVariance.map((e) => e / sum(pcaExplainedVariance));

  return {
    pcaEigenvectors,
    pcaExplainedVarianceRatio,
    sigma,
    projections,
    groupedProjections,
    groupedEllipses,
    groupedScaledEllipses,
  };
};
