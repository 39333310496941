import { Slider } from 'antd';
import { FC, useEffect, useState } from 'react';
import { FlexCol, FlexRow } from '../common';
import { useMessageContext } from '../reducers/messageContext';
import { thimphuSetPump } from '../serial/thimphu';
import { DEFAULT_THIMPHU_PUMP_POWER } from '../serial/constants';
import { loadPumpPower, savePumpPower } from '../localStorage';

export const ThimphuPumpControlWidget: FC = () => {
  const { thimphuPort } = useMessageContext();
  const [pumpPower, setPumpPower] = useState<number>(DEFAULT_THIMPHU_PUMP_POWER);

  useEffect(() => {
    const _pumpPower = loadPumpPower();
    setPumpPower(_pumpPower);
  }, []);

  return (
    <FlexCol
      style={{
        width: '100%',
        gap: 3,
        alignItems: 'center',
      }}
    >
      Pump Power
      <Slider
        style={{
          width: '100%',
        }}
        min={0}
        max={100}
        value={pumpPower}
        onChange={(value) => {
          setPumpPower(value);
        }}
        onAfterChange={async (value) => {
          if (thimphuPort === undefined) {
            return null;
          }
          console.log('set pump power to: ', value);
          savePumpPower(value);
          void (await thimphuSetPump(thimphuPort, value));
        }}
      />
    </FlexCol>
  );
};
