import { FC, useEffect, useState } from 'react';
import { detectThimphuPort, initializeThimphu } from '../serial/thimphu';
import { readPayloadBlocking } from '../serial/serial';
import { DEFAULT_BLOCKING_LOOP_UNEXPECTED_ERROR_WAIT, DEFAULT_DEVICE_DETECTION_INTERVAL, THIMPHU_LENGHT_N_BYTES } from '../serial/constants';
import { thimphuProtocol } from '../../thimphu_protocol';
import { useMessageContext } from '../reducers/messageContext';
import { v4 as uuidv4 } from 'uuid';
import { useMetadataContext } from '../reducers/metadataContext';
import { ConnectionIndicator } from '../widgets/connectionIndicator';
import { navigatorSupportsSerial } from '../serial/utils';
import { saveSpotsGrid1D } from '../localStorage';

export const ThimphuMessageDispatcher: FC<{
  minimized: boolean;
}> = ({ minimized }) => {
  const { thimphuMessages, addThimphuMessage, thimphuPort, setThimphuPort, thimphuIsConnected, setThimphuIsConnected } = useMessageContext();
  const { setThimphuPlatformId, setThimphuFspMetadata, setThimphuMemInfos } = useMetadataContext();

  useEffect(() => {
    if (!navigatorSupportsSerial()) {
      return;
    }
    if (thimphuPort === undefined || thimphuPort.readable === null || thimphuPort.writable === null) {
      setThimphuIsConnected(false);
      return;
    }
    console.log('thimphu infinite loop: start');
    const reader = thimphuPort.readable.getReader({ mode: 'byob' });
    setThimphuIsConnected(true);
    const loop = async () => {
      while (true) {
        try {
          let payloadBytes = await readPayloadBlocking(reader, THIMPHU_LENGHT_N_BYTES);
          // console.log(`read ${payloadBytes.length} bytes`)
          if (payloadBytes.length === 0) {
            console.log('continuing..');
            continue;
          }
          let platformMsg = thimphuProtocol.PlatformMsg.decode(payloadBytes);
          addThimphuMessage({
            id: uuidv4().toString(),
            message: platformMsg,
          });
        } catch (e: any) {
          if (e && e.message) {
            if (e.message.includes('has been released') || e.message.includes('Releasing')) {
              console.log('thimphu port reader has been released; breaking..');
              break;
            }
            if (e.message.includes('device has been lost')) {
              console.log(e.message);
              break;
            }
            if (e.message.includes('Invalid CRC')) {
              console.debug(e.message);
              continue;
            }
          }
          console.log('thimphu infinite loop unexpected error:', e);
          // wait for 1 second before retrying
          await new Promise((resolve) => setTimeout(resolve, DEFAULT_BLOCKING_LOOP_UNEXPECTED_ERROR_WAIT));
        }
      }
    };
    loop().then(() => {
      console.log('thimphu infinite loop: end');
    });
    return () => {
      console.log('thimphu infinite loop: releasing the reader');
      // This will effectively kill the loop()
      // as it will throw an error on next (or onging) read()
      reader.releaseLock();
      setThimphuIsConnected(false);
    };
  }, [thimphuPort]);

  useEffect(() => {
    if (!navigatorSupportsSerial()) {
      return;
    }
    let mutex = true;
    let i = setInterval(async () => {
      if (!mutex) {
        console.debug('thimphu port detection: port detection mutex is false - skipping');
        return;
      }
      if (thimphuPort !== undefined && thimphuPort.readable !== null && thimphuPort.writable !== null) {
        return;
      }
      console.debug('setting thimphu port detection mutex to false');
      mutex = false;
      // setThimphuPort(undefined)
      setThimphuIsConnected(false);
      setThimphuFspMetadata(undefined);
      try {
        let port = await detectThimphuPort();
        if (port === undefined) {
          console.debug('no thimphu port found');
          mutex = true;
          return;
        }
        console.log('thimphu port found', port);
        try {
          console.log('about to initiallize thimphu port:', port);
          let { platformId, fspMetadata, memInfos } = await initializeThimphu(port);
          setThimphuPlatformId(platformId);
          // if (fspMetadata && fspMetadata.calibration && fspMetadata.calibration.spotsGrid) {
          //     saveSpotsGrid1D(fspMetadata.calibration.spotsGrid)
          // }
          setThimphuFspMetadata(fspMetadata);
          setThimphuMemInfos(memInfos);
        } catch (e) {
          console.log('error initializing thimphu port:', e);
          void (await port.close());
          mutex = true;
          return;
        }
        setThimphuPort(port);
      } catch (e) {
        console.debug('no thimphu port found');
      }
      console.debug('setting thimphu port detection mutex to true');
      mutex = true;
      return;
    }, DEFAULT_DEVICE_DETECTION_INTERVAL);

    return () => {
      console.debug('clearing interval for thimphu port detection');
      clearInterval(i);
      mutex = true;
    };
  }, [thimphuPort]);

  if (!navigatorSupportsSerial()) {
    return null;
  }

  return <ConnectionIndicator name="Control Board" connectPath="/connect/serial" isConnected={thimphuIsConnected} minimized={minimized} queueLength={thimphuMessages.length} />;
};
