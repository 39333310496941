import { FC, useEffect, useRef, useState } from 'react';
import { useMessageContext } from '../reducers/messageContext';
import { ConnectionIndicator } from '../widgets/connectionIndicator';
import { csmMosiWriteToCharacteristic, getBleDeviceCandidates, getBleService, getHeartbeatCharacteristic, getMisoCharacteristic, getMosiCharacteristic, navigatorSupportsWebBle } from '../ble/ble';
import { DEFAULT_DEVICE_DETECTION_INTERVAL } from '../serial/constants';
import {
  CSM_PROTOCOL_COMMAND_TYPE,
  CSM_PROTOCOL_EVENT_TYPE,
  csmMosiGetBiosensorSignalMap,
  csmMosiGetVersions,
  csmMosiStopSampling,
  decodeMisoFrame,
  encodeMosiCommand,
  parseBiosensorSignalMapResponse,
  parseBiosensorsSignalEvent,
  parseEventPayload,
  parseResponsePayload,
} from '../serial/csm';
import { v4 as uuidv4 } from 'uuid';
import { saveSpotsGrid1D } from '../localStorage';
import { Mutex, withTimeout, MutexInterface } from 'async-mutex';

const SPOTGRIDPOR3 = [];

export const CSMBleMessageDispatcher: FC<{
  minimized: boolean;
}> = ({ minimized }) => {
  // Important! Two notification characteristics on a single device causes unknown GATTT error on Androind
  // const [ heartbeatCharacteristic, setHeartbeatCharacteristic ] = useState<BluetoothRemoteGATTCharacteristic | null>(null)
  const [misoCharacteristic, setMisoCharacteristic] = useState<BluetoothRemoteGATTCharacteristic | null>(null);
  const [mosiCharacteristic, setMosiCharacteristic] = useState<BluetoothRemoteGATTCharacteristic | null>(null);

  const mosiMutexRef = useRef<MutexInterface>(withTimeout(new Mutex(), 1000));

  const { csmMessages, csmCommands, csmBleDevice, csmIsConnected, addCSMMessage, addCSMCommand, setCSMBleDevice, setCSMIsConnected, consumeCSMCommand, clearCSMCommands } = useMessageContext();

  const handleHeartbeatCharacteristicValueChanged = (event: Event) => {
    if (event.target === null) {
      return;
    }
    let v = (event.target as BluetoothRemoteGATTCharacteristic).value;
    if (!v) {
      return;
    }
    let d = v.getUint16(0, true);
    console.debug('heartbeat characteristic value changed: nMsg', d);
  };

  const handleMisoCharacteristicValueChanged = (event: Event) => {
    if (event.target === null) {
      return;
    }
    let v = (event.target as BluetoothRemoteGATTCharacteristic).value;
    if (!v) {
      return;
    }
    let frame = decodeMisoFrame(new Uint8Array(v.buffer));

    if (frame.LocalCRC !== frame.RemoteCRC) {
      return;
    }

    if (frame.Type < 0x80) {
      // miso responses
      console.log('miso response', frame);
      addCSMMessage({
        id: uuidv4().toString(),
        ts: Date.now(),
        message: frame,
      });
      if (frame.Type === CSM_PROTOCOL_COMMAND_TYPE.GetBiosensorSignalMap) {
        console.log('got biosensor signal map', frame.Payload);
        try {
          console.log(frame.Payload);
          let responsePayload = parseResponsePayload(frame.Payload);
          console.log('responsePayload', responsePayload);
          let spotsgrid1d = parseBiosensorSignalMapResponse(responsePayload.Data);
          //spotsgrid1d.shift();
          // [66, 63, 25, 1, 64, 27, 19, 24, 29, 26, 18, 28, 20, 21, 62, 30, 23, 55, 54, 22, 65]
          console.log('biosensor signal map', spotsgrid1d);
          saveSpotsGrid1D(spotsgrid1d);
        } catch (e: any) {
          console.log('error parsing biosensor signal map response', e.message);
        }
      }
    } else {
      console.log('frame type is:', frame.Type)
      // miso events
      switch (frame.Type) {
        case CSM_PROTOCOL_EVENT_TYPE.BiosensorsSignalEvent:
          addCSMMessage({
            id: uuidv4().toString(),
            ts: Date.now(),
            message: frame,
          });
          break;
        case CSM_PROTOCOL_EVENT_TYPE.ErrorEvent:
          console.log('csm protocol error: ', frame.Payload);
          break;
        case CSM_PROTOCOL_EVENT_TYPE.HeartbeatEvent:
          let event = parseEventPayload(frame.Payload);
          // console.log('csm protocol heartbeat: ', event.Counter)
          break;
        default:
          console.log('csm protocol unknown: ', frame.Type, frame.Payload);
      }
    }
    // console.log('miso characteristic value', v, frame, event.timeStamp)
  };
  // Important! Two notification characteristics on a single device causes unknown GATTT error on Androind
  // useEffect(() => {
  //     if (heartbeatCharacteristic === null) {
  //         return
  //     }
  //     heartbeatCharacteristic.oncharacteristicvaluechanged = handleHeartbeatCharacteristicValueChanged
  //     heartbeatCharacteristic.startNotifications()
  //     console.log('heartbeat characteristic with value changed handler', heartbeatCharacteristic)
  // }, [heartbeatCharacteristic])

  useEffect(() => {
    console.log('test');
    if (misoCharacteristic === null) {
      return;
    }
    try {
      misoCharacteristic.oncharacteristicvaluechanged = handleMisoCharacteristicValueChanged;
      misoCharacteristic.startNotifications();
      console.log('miso characteristic with value changed handler', misoCharacteristic);
    } catch (e: any) {
      console.log('error starting notifications for miso characteristic', e.message);
    }
  }, [misoCharacteristic]);

  useEffect(() => {
    if (mosiCharacteristic === null) {
      return;
    }
    clearCSMCommands();
    addCSMCommand({
      id: uuidv4().toString(),
      message: {
        CmdType: CSM_PROTOCOL_COMMAND_TYPE.StopSampling,
      },
    });
    addCSMCommand({
      id: uuidv4().toString(),
      message: {
        CmdType: CSM_PROTOCOL_COMMAND_TYPE.GetBiosensorSignalMap,
      },
    });
    addCSMCommand({
      id: uuidv4().toString(),
      message: {
        CmdType: CSM_PROTOCOL_COMMAND_TYPE.GetVersions,
      },
    });
  }, [mosiCharacteristic]);

  useEffect(() => {
    console.log('test2');
    if (csmCommands.length === 0) {
      console.log('csm ble dispatcher: no commands to send');
      return;
    }
    if (csmBleDevice?.gatt?.connected === false) {
      console.log('csm ble dispatcher: device is not connected. will not send commands');
      return;
    }
    if (mosiCharacteristic === null) {
      console.log('csm ble dispatcher: mosi characteristic is null. will not send commands');
      return;
    }
    let t = Date.now();
    console.log('acquiring mosi mutex..');
    mosiMutexRef.current.acquire().then((release) => {
      console.log('mosi mutex acquired after ', Date.now() - t, 'ms');
      for (let cmd of csmCommands) {
        console.log('csm ble dispatcher: sending command', cmd.message.CmdType);
        try {
          let frame = encodeMosiCommand(cmd.message.CmdType, cmd.message.Payload);
          console.log('sleeping for 200ms');
          setTimeout(() => {
            // console.log('writing to mosi characteristic', frame)
            csmMosiWriteToCharacteristic(mosiCharacteristic, frame).finally(() => {
              consumeCSMCommand(cmd.id);
              console.log('releasing mosi mutex');
              release();
            });
          }, 200);
        } catch (e: any) {
          console.log('error writing to mosi characteristic', e.message);
        }
        break;
      }
    });
  }, [csmCommands, mosiCharacteristic, csmBleDevice]);

  useEffect(() => {
    console.log('test3');

    if (csmBleDevice === undefined) {
      return;
    }
    getBleService(csmBleDevice)
      .then((service) => {
        // Important! Two notification characteristics on a single device causes unknown GATTT error on Androind
        // getHeartbeatCharacteristic(service)
        // .then(_heartbeatCharacteristic => {
        //     console.log('got heartbeat characteristic', _heartbeatCharacteristic)
        //     setHeartbeatCharacteristic(_heartbeatCharacteristic)
        // });
        getMisoCharacteristic(service).then((_misoCharacteristic) => {
          console.log('got miso characteristic', _misoCharacteristic);
          setMisoCharacteristic(_misoCharacteristic);
        });
        getMosiCharacteristic(service).then((_mosiCharacteristic) => {
          console.log('got mosi characteristic', _mosiCharacteristic);
          setMosiCharacteristic(_mosiCharacteristic);
        });
      })
      .catch((e: any) => {
        console.log('error subscribing device', e);
      });
  }, [csmBleDevice]);

  useEffect(() => {
    const onBeforeUnload = async (event: any) => {
      if (csmBleDevice !== undefined && csmBleDevice.gatt !== undefined && csmBleDevice.gatt.connected) {
        console.log('on beforeunload: disconnecting csm ble device');
        csmBleDevice.gatt.disconnect();
      }
      let confirmationValue = 'Quitting the page will disconnect your device from Neose BLE. Proceed?';
      event.returnValue = confirmationValue;
      return confirmationValue;
    };
    console.log('adding event listener for beforeunload');
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      console.log('removing event listener for beforeunload');
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (!navigatorSupportsWebBle()) {
      return;
    }
    var mutex = true;
    let i = setInterval(async () => {
      if (!mutex) {
        console.debug('refkit port detection: port detection mutex is false - skipping');
        return;
      }
      if (csmBleDevice !== undefined && csmBleDevice.gatt !== undefined && csmBleDevice.gatt.connected) {
        return;
      }
      console.debug('setting csm ble detection mutex to false');
      mutex = false;
      setCSMBleDevice(undefined);
      setCSMIsConnected(false);
      try {
        let device: BluetoothDevice | null = null;
        let deviceCandidates = await getBleDeviceCandidates();
        console.log('got ble devices', deviceCandidates);
        for (let _device of deviceCandidates) {
          try {
            let _service = await getBleService(_device);
            console.log('got ble service', _service);
            device = _device;
            break;
          } catch (e: any) {
            console.log('error getting ble service', e.message);
          }
        }
        if (device === null) {
          console.debug('no csm ble device gotten nor found');
          mutex = true;
          return;
        }
        // forget all the other devices
        for (let _device of deviceCandidates) {
          if (_device.id !== device.id) {
            console.log('forgetting unused device', _device);
            void (await _device.forget());
          }
        }
        setCSMBleDevice(device);
        setCSMIsConnected(true);
        console.debug('csm ble device found', device);
      } catch (e: any) {
        console.debug('no csm ble device found', e.message);
      }
      console.debug('setting csm ble detection mutex to true');
      mutex = true;
      return;
    }, DEFAULT_DEVICE_DETECTION_INTERVAL);
    return () => {
      console.debug('clearing interval for csm ble device detection');
      clearInterval(i);
      console.debug('setting csm ble device detection mutex to true');
      mutex = true;
      return;
    };
  }, [csmBleDevice]);

  return <ConnectionIndicator name="CSM over BLE" connectPath="/connect/ble" isConnected={csmIsConnected} minimized={minimized} queueLength={csmMessages.length} />;
};
